import { useContext, useEffect, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { getAuth, onAuthStateChanged, User as FirebaseUser, signOut as firebaseSignOut } from 'firebase/auth';
import { appConfig } from './Config';
import { api } from './ApiService';
import { User } from './Types';
import GlobalContext from './GlobalContext';

const useAuth = () => {

  const { user, setUser, token, setToken, initiative, setInitiative, initiatives, setInitiatives } = useContext(GlobalContext);

  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const auth0 = useAuth0();
  const getAccessTokenSilently = auth0?.getAccessTokenSilently;

  const firebaseAuth = getAuth();
  let isLoadingToken = false;

  useEffect(() => {

    if (isLoadingToken) return;
    isLoadingToken = true;    

    if (appConfig.auth0 == true) {
      const { user, isLoading, isAuthenticated, logout, loginWithRedirect } = auth0;
      
      if (user) {
        const customUser = {
          uid: user?.sub,
          avatar: user?.picture,
          name: user?.name,
          email: user?.email,
          email_verified: user?.email_verified,
          id: user?.sub,
          stripe_id: null,
          openai: false,
          instance_id: null,
          dark_mode: null,
          role: null,
          type: "Auth0",
        };

        window.dispatchEvent(new Event('uerAuhenticated'));
        // TO BE FIXED!
        // setUser(customUser);
      } else {
        // setUser(null);
      }

      setIsLoading(isLoading);
      setIsAuthenticated(isAuthenticated);

      if (!isLoading && isAuthenticated && user && getAccessTokenSilently != null) {
        getAccessTokenSilently().then(token => {
          setToken(token);
          setIsAuthenticated(true);
          (window as any).accessToken = token;
          window.dispatchEvent(new Event('uerAuhenticated'));
        }).catch(error => {
          console.error('Error obtaining access token:', error);
        });
      }
      isLoadingToken = false;    
    } 
    else if (firebaseAuth) {
      
      console.log("Firebase auth.")

      const unsubscribe = onAuthStateChanged(firebaseAuth, async (currentUser: FirebaseUser | null) => {
        setIsLoading(true);
        if (currentUser && !token) {
    
          const token = await currentUser.getIdToken();
          console.log("Window.token: "+ token);
          (window as any).accessToken = token;
          setToken(token);
          setIsAuthenticated(true);
          window.dispatchEvent(new Event('uerAuhenticated'));
        } 
        setIsLoading(false);
      });

      isLoadingToken = false;    
      return () => unsubscribe();
    }
  }, [firebaseAuth, appConfig.auth0, auth0, getAccessTokenSilently]);

  const logout = async () => {

    if (appConfig.auth0 == true) {
      
      auth0.logout();
    } 
    else if (firebaseAuth) {

      try {
        await firebaseSignOut(firebaseAuth);
        setToken(null);
        setUser(null);
        setIsAuthenticated(false);
        setInitiative(undefined);
        setInitiatives(null);
        (window as any).location = "/";
      } catch (error) {
        console.error('Logout failed:', error);
      }
    }
  };
  
  return { isLoading, isAuthenticated, token, logout, loginWithRedirect: auth0.loginWithRedirect };
};

export default useAuth;