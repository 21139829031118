import { IonBreadcrumbs, IonBreadcrumb, IonIcon, IonCard, IonCardTitle, IonCardHeader, IonButton, IonCardContent, IonInput, IonModal, IonTextarea, IonProgressBar, ItemReorderEventDetail } from '@ionic/react';
import AppContainer from '../AppContainer';
import { chatbubbleEllipses, diamond, folder } from 'ionicons/icons';
import Menu from '../components/Menu';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router';
import { useContext, useEffect, useState } from 'react';
import GlobalContext from '../domain/GlobalContext';
import { api } from '../domain/ApiService';
import { Assistant, Folder, Initiative, KnowledgeBaseItem } from '../domain/Types';
import ListView from '../components/ListView';
import Prompts from '../components/Prompts';

const Page: React.FC = () => {

  const history = useHistory();

  const { user, initiative } = useContext(GlobalContext);

  const location = useLocation();

  const pathSegments = location.pathname.split('/').filter(Boolean);
  const client = pathSegments[0];
  const folder = pathSegments[1];

  return (
    <>
      <IonCard className='filled' style={{ padding: "10px" }}>
        <IonCardHeader>
          <IonCardTitle>Prompts</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <Prompts showHeader={true} showActions={true} showSearch={true} />
        </IonCardContent>
      </IonCard>
    </>
  );
};

const Breadcrumb: React.FC = () => {
    return (
      <IonBreadcrumbs>
        <IonBreadcrumb routerLink="/chat">
          <IonIcon slot="start" icon={folder}></IonIcon>
          Prompts
        </IonBreadcrumb>
      </IonBreadcrumbs>
    );
  };
  
const Container: React.FC<{}> = () => { return <AppContainer ContentComponent={Page} BreadcrumbComponent={Breadcrumb} MenuComponent={Menu} />; };
export default Container;