import React, { useCallback, useEffect, useState } from 'react';
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, signInWithRedirect, sendEmailVerification, sendPasswordResetEmail, confirmPasswordReset } from 'firebase/auth';
import * as firebaseui from 'firebaseui';
import { GoogleAuthProvider, GithubAuthProvider } from 'firebase/auth';
import './theme/login.css';
import './theme/variables.css';
import './theme/menu.css';
import './theme/pages.css';
import './theme/forms.css';
import './theme/login.css';
import { IonButton, IonIcon, IonInput, IonLabel, useIonToast } from '@ionic/react';
import { appConfig } from './domain/Config';
import { sign } from 'crypto';
import zxcvbn from 'zxcvbn';
import { alertCircleOutline, checkmarkCircleOutline, closeCircle } from 'ionicons/icons';

const Login: React.FC = () => {

  const [signupView, setSignupView] = useState(false);
  const [passwordView, setPasswordView] = useState(false);
  const [confirmPasswordView, setConfirmPasswordView] = useState(false);

  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
  const [passwordStrength, setPasswordStrength] = useState(-1);
  const [passwordMatch, setPasswordMatch] = useState(false);
  
  const [present] = useIonToast();

  const presentToast = (message: string) => {
    present({
      message: message,
      duration: 5000,
      position: "top",
    });
  };

  useEffect(() => {

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const mode = urlParams.get('mode');
    if (mode === "resetPassword")
      setConfirmPasswordView(true);

  }, []);

  const updateView = useCallback(async (viewName: string) => {

    if (viewName == "login") {
      setSignupView(false);
      setPasswordView(false);
      setConfirmPasswordView(false);
    }
    else if (viewName == "signup") {

    if (appConfig.signup_allowed) {

        setPasswordStrength(-1);
        setPasswordMatch(false);
        setSignupView(true);
        setPasswordView(false);
        setConfirmPasswordView(false);
      }
      else {

        setSignupView(false);
        setPasswordView(false);
        setConfirmPasswordView(false);
      }
    }
    else if (viewName == "reset") {

      setPasswordStrength(-1);
      setPasswordMatch(false);
      setSignupView(false);
      setPasswordView(true);
      setConfirmPasswordView(false);
    }      
    else if (viewName == "confirm") {
      setSignupView(false);
      setPasswordView(false);
      setConfirmPasswordView(true);
    }      
  }, []);

  const handleLogin = async (e: React.FormEvent) => {

      e.preventDefault();
      setLoading(true);

      const auth = getAuth();

      try { 

          await signInWithEmailAndPassword(auth, email, password);

      } catch (err) {

          presentToast("Failed to sign in. Please check your credentials.");            
      }

      setLoading(false);
    };

  const handleSignup = async (e: React.FormEvent) => {

    if (!appConfig.signup_allowed) {

      presentToast("New sign-ups are currently disabled.");            
      return;
    }

    if (!passwordMatch || passwordStrength < 2) {

      presentToast("Please check your password.");            
      return;
    }

    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!regex.test(email)) {

      presentToast("Please check your email address.");            
      return;
    }

    e.preventDefault();
    setLoading(true);

    const auth = getAuth();

    try {

        const userCredential = await createUserWithEmailAndPassword(auth, email, newPassword);
        await sendEmailVerification(userCredential.user)
    } catch (err) {

      presentToast("Could not sign up: "+ err);
    }

    setLoading(false);
  };    

  const handleReset = async (e: React.FormEvent) => {

    e.preventDefault();
    setLoading(true);

    const auth = getAuth();

    try {
        await sendPasswordResetEmail(auth, email)
        presentToast("Email sent. Please check your inbox.");            
        updateView("login");
    } catch (err) {
        presentToast("Failed to send email: "+ err);
    }

    setLoading(false);
  };    
  
  const handleConfirmReset = async (e: React.FormEvent) => {

    e.preventDefault();
    setLoading(true);

    const auth = getAuth();

    try {

      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const actionCode = urlParams.get('oobCode');

      if (actionCode != null) {        

        await confirmPasswordReset(auth, actionCode, newPassword);
        presentToast("Password has been reset successfully. Please log in with your new password.");
      }
      else {

        presentToast("Could not reset password. Link has expired.");
      }
    } 
    catch (err) {
        presentToast("Could not reset password. "+ err);
    }

    updateView("login");
    setLoading(false);
  };    

  const handleGoogle = () => {

    setLoading(true);

    try {
      signInWithRedirect(getAuth(), new GoogleAuthProvider());
    } catch (err) {
      presentToast("Failed to connect Google: "+ err);
    }
    setLoading(false);
  };

  const handlePasswordChange = (value: string | undefined, primary: boolean) => {

    if (!value) {
      setPasswordStrength(-1);
      setPasswordMatch(false);
      return;
    }
  
    let p1 = newPassword;
    let p2 = newPasswordConfirm;

    if (primary) {
      setNewPassword(value);     
      p1 = ""+ value; 
    } else {
      setNewPasswordConfirm(value);
      p2 = ""+ value; 
    }
  
    setPasswordMatch(p1 === p2);
  
    if (primary) {
      const result = zxcvbn(value);
      setPasswordStrength(result.score);
    }
  };

  return (
  <div className='form login-form'>

      <div style={{marginBottom: "10px"}}>
          <img alt="" src="/assets/icon/favicon.png" className="logo" />
      </div>
      {!signupView && !confirmPasswordView ? 

      <>
          {(passwordView) ? 
              <>
                  <p>Reset your password</p>
                  <p><small>Enter the email address associated with your account, and we'll send you a link to reset your password.</small></p>                    
                  <IonInput
                      type="email"
                      className='form-element'
                      label="Email address"
                      labelPlacement="floating"
                      fill="outline"
                      value={email}
                      placeholder="Email address"
                      onIonChange={(e) => setEmail(e.detail.value!)}
                  />
                  <p>
                      <small><span className='link link-anchor' onClick={() => updateView("login")}>Back to login</span></small>
                  </p>
                  <IonButton disabled={loading} onClick={handleReset} style={{marginLeft: "0px"}}>
                      Continue
                  </IonButton>
              </>
              : 
          <>
          <p>Log in to {appConfig.appName}</p>
          { appConfig.signup_allowed &&
          <p>
              <small>Don't have an account? <span className='link link-anchor' onClick={() => updateView("signup")}>Sign up</span>.</small>
          </p> }
          <IonInput
              type="email"
              className='form-element'
              label="Email address"
              labelPlacement="floating"
              fill="outline"
              value={email}
              placeholder="Email address"
              onIonChange={(e) => setEmail(e.detail.value!)}
          />
          <IonInput
              type="password"
              className='form-element'
              label="Password"
              labelPlacement="floating"
              fill="outline"
              placeholder="Password"
              onIonChange={(e) => setPassword(e.detail.value!)}
          />
          <p>
              <small><span className='link link-anchor' onClick={() => updateView("reset")}>Forgot your password?</span></small>
          </p>
          <IonButton disabled={loading} onClick={handleLogin} style={{marginLeft: "0px"}}>
              Continue
          </IonButton>
          </> 
          }
      </>
      : 
      <>      

          {confirmPasswordView ?
          <>
            <p>Update your password</p>
          </>
          :
          <>
            <p>Sign up to {appConfig.appName}</p>
            <p>
                <small>Already a user? <span className='link link-anchor' onClick={() => updateView("login")}>Log in</span>.</small>
            </p>
            <IonInput
                type="email"
                className='form-element'
                label="Email address"
                labelPlacement="floating"
                fill="outline"
                value={email}
                placeholder="Email address"
                onIonChange={(e) => setEmail(e.detail.value!)}
            />
          </>}          
          <div style={{ marginBottom: "15px" }}>
          <IonInput
              id="newPassword"
              type="password"
              className='form-element'
              label="Password"
              labelPlacement="floating"
              fill="outline"
              placeholder="Password"
              onInput={(e) => handlePasswordChange(e.currentTarget.value as string, true)}
              onIonChange={(e) => setNewPassword(e.detail.value!)}
          />
          {passwordStrength === 0 ? <IonLabel color="danger">Very weak password</IonLabel> :
           passwordStrength === 1 ? <IonLabel color="danger">Weak password</IonLabel> :
           passwordStrength === 2 ? <IonLabel color="warning">Fair password</IonLabel> :
           passwordStrength === 3 ? <IonLabel color="success">Good password</IonLabel> :
           passwordStrength === 4 ? <IonLabel color="success">Strong password</IonLabel> :
           <IonLabel color="medium">Type a new password</IonLabel>}          
          </div>
          <IonInput
              id="confirmNewPassword"
              type="password"
              className='form-element'
              label="Password (again)"
              labelPlacement="floating"
              fill="outline"
              placeholder="Password (again)"
              onInput={(e) => {
                handlePasswordChange(e.currentTarget.value as string, false);
              }}
              onIonChange={(e) => setNewPasswordConfirm(e.detail.value!)}
            />
          <div style={{ marginBottom: "15px" }}>
          {passwordMatch ? 
            <IonLabel color="success">Password confirmed</IonLabel>
            :
            <IonLabel color="medium">Confirm your passwords</IonLabel>          
          }
          
          </div>
          <IonButton onClick={confirmPasswordView ? handleConfirmReset : handleSignup} style={{marginLeft: "0px"}}>
              Continue
          </IonButton>                
      </>
      }
      {appConfig.signin_google && !confirmPasswordView &&
        <div style={{marginTop: "20px"}}>
          <IonButton className='basic' onClick={handleGoogle} style={{marginLeft: "0px"}}>
              {signupView ? "Sign up" : "Sign in"} with Google
          </IonButton>                
        </div>  
      }
  </div>
);
};

export default Login;