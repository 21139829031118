import { NestedRoute } from '../../domain/Types';
import { barChart, bookmark, bulb, create, diamond, documentText, layers, people, share, star } from 'ionicons/icons';
import Initiative from '../../pages/Initiative';
import Ideas from './pages/Ideas';
import Content from './pages/Content';
import CompetitorAnalysis from './pages/CompetitorAnalysis';
import ContentAnalysis from './pages/ContentAnalysis';
import WebAnalytics from './pages/WebAnalytics';

const contentRoutes: NestedRoute[] = [
  { 
    path: '/', 
    component: Initiative, 
    exact: true, 
    menu: false,
    icon: layers, 
    label: 'Web analytics' 
  },
  { 
    path: '/web-analytics', 
    component: WebAnalytics, 
    exact: true, 
    menu: true,
    icon: barChart, 
    label: 'Web analytics' 
  },
  { 
    path: '/position-analysis', 
    component: CompetitorAnalysis, 
    exact: true, 
    menu: true,
    icon: layers, 
    label: 'Position analysis' 
  },
  { 
    path: '/content-analysis', 
    component: ContentAnalysis, 
    exact: true, 
    menu: true,
    icon: documentText, 
    label: 'Content analysis' 
  },
  { 
    path: '/ideas', 
    component: Ideas, 
    exact: true, 
    menu: true,
    icon: bulb, 
    label: 'Generate ideas' 
  },
  { 
    path: '/content', 
    component: Content, 
    exact: true, 
    menu: true,
    icon: create, 
    label: 'Create content' 
  }
];

export default contentRoutes;

