import React, { useState, useMemo, useEffect, useContext } from 'react';
import {
  IonGrid, IonRow, IonCol, IonReorder, IonReorderGroup, IonIcon,
  IonCard, IonCardHeader, IonCardTitle, IonCardContent,
  IonButtons, IonToolbar, IonButton
} from '@ionic/react';
import { add, addCircle, addCircleOutline, albumsOutline, ellipsisHorizontal, listOutline } from 'ionicons/icons';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import '../theme/lists.css';
import GlobalContext from '../domain/GlobalContext';

type ColumnDefinition = {
  attribute?: string;
  icon?: string;
  dynamicIcon?: (item: any) => string;
  onClick?: (item: any) => void;
  filePath?: string;
  label: string;
  size?: string | "auto";
  primary?: boolean;
  tags?: string[];
};

type ListViewProps<T> = {
  items: T[] | null | undefined;
  columns?: ColumnDefinition[];
  enableReorder: boolean;
  editMode?: 'owner' | 'admin' | 'all';
  defaultLayout: 'table' | 'cards';
  showHeader?: boolean;
  showActions?: boolean;
  showCreate?: boolean;
  onEdit?: (item: T | null) => void;
  onClick?: (item: T) => void;
  onReorder?: (event: CustomEvent) => void | undefined;
  tags?: string[];
};

const ListView = <T extends { [key: string]: any }>({
  items, columns, enableReorder, editMode, defaultLayout, showActions, showCreate, showHeader, onEdit, onReorder, onClick
}: ListViewProps<T>) => {

  const { user } = useContext(GlobalContext);

  if (!editMode || editMode == null)
    editMode = "all";

  if (!items || items == null)
    items = [];

  if (!columns || columns == null)
    columns = [];
  
  const [layout, setLayout] = useState<'table' | 'cards'>(defaultLayout);
  const primaryColumn = columns.find(column => column.primary);

  type SizeKey = 'small' | 'medium' | 'large' | 'auto';

  const renderTags = (tags: string[], item: T) => {
    if (!tags) return null;
    return tags.map((tagDef, index) => {
      const tagContent = tagDef.replace(/\{([^}]+)\}/g, (_, key) => item[key] || '');
      return <span className='tag'>{tagContent}</span>;
    });
  };

  // Function to calculate size mapping
  const calculateSizeMapping = () => {
    const sizeValues: { [key in SizeKey]: number } = { small: 1, medium: 2, large: 3, auto: 1 };
    let totalSizeValue = columns?.reduce((total, col) => {
      // Ensure the size key is a valid key of sizeValues
      const sizeKey: SizeKey = col.size as SizeKey || 'auto';
      return total + sizeValues[sizeKey];
    }, 0);
  
    return columns?.map(col => {
      const sizeKey: SizeKey = col.size as SizeKey || 'auto';
      if (!totalSizeValue)
        totalSizeValue = 1;

      return {
        ...col,
        calculatedSize: `${Math.round((sizeValues[sizeKey]) / totalSizeValue * 12)}`
      };
    });
  };

  const getDynamicIcon = (item: T, column: ColumnDefinition) => {
    if (column.dynamicIcon) {
      const iconName = column.dynamicIcon(item);
      return iconName;
    }
    return column.icon;
  };  

  function getAttributeValue<T extends { [key: string]: any, content_json?: any }>(item: T, attribute: string): any {
    if (item.content_json && item.content_json[attribute] !== undefined) {
      return item.content_json[attribute];
    }
    return item[attribute];
  }  

  const fetchSignedUrl = async (image_url : string) => {

    if (!image_url) return null;
    const regex = /https:\/\/storage\.googleapis\.com\/[^\/]+\/(.+)/;
    const match = image_url.match(regex);
    const imagePath = match ? match[1] : null;    

    if (imagePath) {

      const storage = getStorage();
      const storageRef = ref(storage, imagePath);
    
      try {
        const url = await getDownloadURL(storageRef);
        return url;
      } catch (error) {
        console.error('Error fetching signed URL:', error);
      }
    }

    return null;
  };
  
  const [urls, setUrls] = useState<{ [key: string]: string | null }>({});
  useEffect(() => {
    const fetchUrls = async () => {

      const newUrls: { [key: string]: string | null } = {};
      if (items) {
        for (const item of items) {
          const url = await fetchSignedUrl((item.content_json && item.content_json.image_url != undefined && item.content_json.image_url != null) ? item.content_json.image_url : item.image_url);
          newUrls[(item.content_json && item.content_json.image_url != undefined && item.content_json.image_url != null) ? item.content_json.image_url : item.image_url] = url;
        }
      }

      setUrls(newUrls);
    };
  
    fetchUrls();
  }, [items]);

  // Memoize the size mapping
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sizeMapping = useMemo(() => calculateSizeMapping(), [columns]);

  const renderRow = (item: T, index: number) => {
    return (
      <IonRow key={index} className="list-row">
        {enableReorder && <IonCol size="auto"><IonReorder /></IonCol>}
        {sizeMapping?.map((column) => (
          <IonCol key={`${item.id}-${column.attribute || column.label}`} size={column.calculatedSize} onClick={column.onClick ? () => column.onClick!(item) : (onClick ? () => onClick(item) : undefined)}>
            {column.attribute && <div className={((onClick && column.primary) || column.onClick) ? 'list-content link link-anchor' : 'list-content' }>          

              {column.attribute == "image_prompt" && urls[(item.content_json && item.content_json.image_url != undefined && item.content_json.image_url != null) ? item.content_json.image_url : item.image_url] ? (
                <img src={urls[(item.content_json && item.content_json.image_url != undefined && item.content_json.image_url != null) ? item.content_json.image_url : item.image_url] ?? undefined} alt="" style={{ width: "100%", maxWidth: "200px", border: "4px solid #ffffff", borderRadius: "10px" }} />
              ) : 
                <>
                  {getAttributeValue(item, column.attribute) ? (getAttributeValue(item, column.attribute).length > 100 ? `${getAttributeValue(item, column.attribute).slice(0, 200)}...` : getAttributeValue(item, column.attribute)) : ''}            
                </>              
              }

            </div>}
            {column.tags && <div className={(onClick && column.primary) ? 'list-tags link link-anchor' : 'list-tags' }>{renderTags(column.tags, item)}</div>}
            {column.icon && <div className={((onClick && column.primary) || column.onClick) ? 'list-content link link-anchor' : 'list-content' }><IonIcon icon={column.icon}></IonIcon></div>}
            {column.dynamicIcon && <div className={((onClick && column.primary) || column.onClick) ? 'list-content link link-anchor' : 'list-content' }><IonIcon icon={getDynamicIcon(item, column)}></IonIcon></div>}
          </IonCol>
        ))}
        {(showActions || (editMode == "owner" && user?.auth_id === item.created_by)) ? 
          <IonIcon icon={ellipsisHorizontal} onClick={onEdit ? () => onEdit(item) : undefined} className='list-edit-table' />
        :<></> }
      </IonRow>
    );
  };

  const renderCard = (item: T, index: number) => {
    return (
      <IonCol key={item.id} size-xs="12" size-sm="6" size-md="4" size-lg="3">
        <IonCard className="list">
          <IonCardHeader className="list">
            <IonCardTitle onClick={onClick ? () => onClick(item) : undefined} className={onClick ? "link list" : "list"}>
              {primaryColumn && primaryColumn.attribute ? getAttributeValue(item, primaryColumn.attribute) : item.name}
            </IonCardTitle>
          </IonCardHeader>
          <IonCardContent className="list">
            <div>
              {sizeMapping?.map((column) => (
                !column.primary ? (
                  <div className="list-attribute" key={`${item.id}-${column.attribute || column.label}`}>
                    {column.attribute && <span className="list-attribute-label">{column.label}: </span>}
                    {column.attribute && <span>{getAttributeValue(item, column.attribute)}</span>}
                    {column.tags && <span>{renderTags(column.tags, item)}</span>}
                  </div>
                ) : null 
              ))}
            </div>
          </IonCardContent>
          {showActions && onEdit ? 
            <IonIcon icon={ellipsisHorizontal} onClick={() => onEdit(item)} className='link list-edit-card' />
          :<></> }
        </IonCard>
      </IonCol>
    );
  };

  if (!items) {

    return (<></>);
  }
  else {

    return (
      <div className="list-container">      
        {(showActions == null || showActions != false || showCreate == true) ? 
            (<IonToolbar className='list'>
                <IonButtons slot="start" className="list">
                  <IonButton color="primary" onClick={onEdit ? () => onEdit(null) : undefined}>
                    <IonIcon icon={addCircle} size='small' />
                  </IonButton>
                </IonButtons>
                <IonButtons slot="end" className="list">
                  <IonButton onClick={() => setLayout('table')} color={layout === 'table' ? "primary" : undefined}>
                    <IonIcon icon={listOutline} size='small' />
                  </IonButton>
                  <IonButton onClick={() => setLayout('cards')} color={layout === 'cards' ? "primary" : undefined}>
                    <IonIcon icon={albumsOutline} size='small' />
                  </IonButton>
                </IonButtons>
              </IonToolbar>)
              :
              (<></>)}        
        <IonGrid>
          {layout === 'table' ? (
            <>
              {(showHeader == null || showHeader != false) ? 
              (<><IonRow>
                {enableReorder && <IonCol size="auto"><div style={{ width: "30px" }} >&nbsp;</div></IonCol>}
                {sizeMapping?.map(column => (
                  <IonCol key={`header-${column.attribute}`} size={column.calculatedSize}><div className='list-header'>{column.label}</div></IonCol>
                ))}
                {showActions ? 
                <IonCol size="auto"><div style={{ width: "30px" }} >&nbsp;</div></IonCol>
                :<></> }
              </IonRow></>) 
              :
              (<></>)}
              <IonReorderGroup disabled={!enableReorder} onIonItemReorder={onReorder}>
                {items.map((item, index) => renderRow(item, index))}
              </IonReorderGroup>
            </>
          ) : (
            <IonRow>
              {items.map((item, index) => renderCard(item, index))}
            </IonRow>
          )}
        </IonGrid>
      </div>
    );
  }
};

export default ListView;