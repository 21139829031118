import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonIcon, IonInput, IonProgressBar } from '@ionic/react';
import { appConfig } from './domain/Config';
import { mailOutline } from 'ionicons/icons';
import useAuth from './domain/Auth';
import GlobalContext from './domain/GlobalContext';
import { useContext, useEffect, useState } from 'react';
import { api } from "./domain/ApiService";
import ListView from './components/ListView';
import { Initiative } from './domain/Types';
import CreateInitiative from './components/CreateInitiative';

const Home: React.FC = () => {

  const { user, initiatives, initiative, setInitiative, isDarkMode, setIsDarkMode, isMenuVisible, setIsMenuVisible, smallScreen } = useContext(GlobalContext);
  const [filteredInitiatives, setFilteredInitiatives] = useState<Initiative[]>([]);
  const [templates, setTemplates] = useState<Initiative[]>([]);
  const [clickedTemplate, setClickedTemplate] = useState<Initiative>();
  const [showCreateInitiativeModal, setShowCreateInitiativeModal] = useState(false);

  useEffect(() => {

    if (initiatives != null) {

      setTemplates(initiatives.filter(initiative => {
        return initiative.template ? initiative.template : false;
      }));

      let filtered = initiatives.filter(initiative => {
        if (user?.auth_id && initiative && !initiative.template) {

            const isOwnerOrCollaborator = !initiative.owners || !initiative.collaborators || initiative.owners.includes(user.auth_id) || initiative.collaborators.includes(user.auth_id);
            const isCollaboratorOrAllUsers = !initiative.collaborators || initiative.collaborators.includes(user.auth_id) || initiative.collaborators.length === 0 || initiative.collaborators.includes("allUsers");
            return isOwnerOrCollaborator || isCollaboratorOrAllUsers;
        }
        return false;
      });

      setFilteredInitiatives(filtered);
    }

  }, [initiatives]);
    
  const handleClick = (clickedInitiative: Initiative) => {

    setInitiative(clickedInitiative);
  };

  const handleTemplateClick = (clickedInitiative: Initiative) => {

    setClickedTemplate(clickedInitiative);
    setShowCreateInitiativeModal(true);
  };

  const handleClose = () => {

    setClickedTemplate(undefined);
    setShowCreateInitiativeModal(false);
  };

  return (

    <>
    {initiatives != null ?
    <>
      <IonCard className='filled' style={{ padding: "10px" }}>
        <IonCardContent>
          <ListView<Initiative>
              items={filteredInitiatives}
              columns={[
                { attribute: 'name', label: 'Name', size: "small", primary: true },
                { attribute: 'instructions', label: 'Instructions', size: "small" }
              ]}
              onClick={handleClick}
              enableReorder={false}
              defaultLayout={'table'}
              showActions={false}
          />
        </IonCardContent>
      </IonCard>
      <IonCard className='filled' style={{ padding: "10px" }}>
        <IonCardContent>
          <ListView<Initiative>
              items={templates}
              columns={[
                { attribute: 'name', label: 'Name', size: "small", primary: true },
                { attribute: 'instructions', label: 'Instructions', size: "small" }
              ]}
              onClick={handleTemplateClick}
              enableReorder={false}
              defaultLayout={'cards'}
              showActions={false}
          />
        </IonCardContent>
      </IonCard>
      <CreateInitiative
        show={showCreateInitiativeModal}
        onClose={handleClose}
        forTemplateId={clickedTemplate?.id}
      />
    </>
    :
      <IonProgressBar type="indeterminate" color="medium"></IonProgressBar>
    }
    </>

  );
};

export default Home;