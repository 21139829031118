import { IonBreadcrumbs, IonBreadcrumb, IonIcon } from '@ionic/react';
import AppContainer from '../../../AppContainer';
import { chatbubbleEllipses, diamond, folder } from 'ionicons/icons';
import Menu from '../../../components/Menu';
import { useContext, useEffect, useState } from 'react';
import GlobalContext from '../../../domain/GlobalContext';

interface Message {
  id: string;
  text: string;
  sender: string;
  timestamp: any;
}

const Page: React.FC = () => {

  const { initiative } = useContext(GlobalContext);  
  const [reportUrl, setReportUrl] = useState('');

  useEffect(() => {
    // Update reportUrl based on selectedDomain
    if (initiative?.name === 'www.meom.fi') {
      setReportUrl('https://lookerstudio.google.com/embed/reporting/12900244-42a6-4d14-949d-3462a453500a/page/ZTfTD');
    } else if (initiative?.name === 'oi.fi') {
      setReportUrl('https://lookerstudio.google.com/embed/reporting/a0c4e9f9-7362-4c65-8967-744a1d96c11e/page/ZTfTD');
    } else if (initiative?.name === 'laurea.fi') {
      setReportUrl('https://lookerstudio.google.com/embed/reporting/1e4b8ecb-2c02-45fa-b940-4c4158fefaa4/page/ZTfTD');
    } else if (initiative?.name === 'www.valteri.fi') {
      setReportUrl('https://lookerstudio.google.com/embed/reporting/140c8e90-eadc-4828-8da3-93e384ae1084/page/ZTfTD');
    } else if (initiative?.name === 'www.nbforum.com') {
      setReportUrl('https://lookerstudio.google.com/embed/reporting/19d49940-0f51-4251-8d08-eba5bb501dcf/page/ZTfTD');
    } else if (initiative?.name === 'fimpec.com') {
      setReportUrl('https://lookerstudio.google.com/embed/reporting/19da4cff-eddb-4fe0-8a74-741a8fc2bab3/page/ZTfTD');
    } else if (initiative?.name === 'www.staffpoint.fi') {
      setReportUrl('https://lookerstudio.google.com/embed/reporting/ae5868c0-576f-4ec7-a9e9-c9dd47cd4d1f/page/ZTfTD');
    } else if (initiative?.name === 'www.hopkins.fi') {
      setReportUrl('https://lookerstudio.google.com/embed/reporting/7d9bd1cc-cecd-476c-b323-4ab05046a6cc/page/ZTfTD');
    } else if (initiative?.name === 'www.pandia.fi') {
      setReportUrl('https://lookerstudio.google.com/embed/reporting/1246a1e5-5be2-4216-8646-a4758cdb5767/page/ZTfTD');
    } else {
      setReportUrl('/placeholder.html');
    }
  }, [initiative]);

  return (
    <div>

      <iframe className="fullscreen" height="700" src={`${reportUrl}`}></iframe>

    </div>
  );
};

const Breadcrumb: React.FC = () => {
    return (
      <IonBreadcrumbs>
        <IonBreadcrumb routerLink="/report">
          <IonIcon slot="start" icon={folder}></IonIcon>
          Content analysis
        </IonBreadcrumb>
      </IonBreadcrumbs>
    );
  };
  
  const Container: React.FC<{}> = () => { return <AppContainer ContentComponent={Page} BreadcrumbComponent={Breadcrumb} MenuComponent={Menu} />; };
  export default Container;