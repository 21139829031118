import React, { useState, useCallback, useContext, useEffect } from 'react';
import { IonModal, IonButton, IonInput, IonTextarea, IonSelect, IonSelectOption, IonSpinner, IonIcon } from '@ionic/react';
import { Initiative } from '../domain/Types';
import { api } from '../domain/ApiService';
import GlobalContext from '../domain/GlobalContext';
import { v4 as uuidv4 } from 'uuid';
import { appConfig } from '../domain/Config';
import { duplicate } from 'ionicons/icons';

interface CreateInitiativeProps {
    show: boolean | undefined;
    onClose: () => void;
    forTemplateId?: number | null;
}

const CreateInitiative: React.FC<CreateInitiativeProps> = ({ show, onClose, forTemplateId }) => {
    const { initiative, setInitiative, initiatives } = useContext(GlobalContext);
    const [initiativeName, setInitiativeName] = useState('');
    const [initiativeInstructions, setInitiativeInstructions] = useState('');
    const [initiativeType, setInitiativeType] = useState<string>(appConfig.initiativeTypes[0]);
    const [loadingModal, setLoadingModal] = useState(false);

    const [templates, setTemplates] = useState<Initiative[]>([]);
    const [templateId, setTemplateId] = useState<number | null>(forTemplateId || null);

    useEffect(() => {
        // Set templates when initiatives change
        if (initiatives != null) {
            const filtered = initiatives.filter(initiative => initiative.template);
            setTemplates(filtered);
        }
    }, [initiatives]);

    // Update templateId when forTemplateId changes
    useEffect(() => {
        if (forTemplateId !== undefined) {
            setTemplateId(forTemplateId);
        }
    }, [forTemplateId]);

    const handleCreateInitiative = useCallback(async (generate: boolean) => {
        setLoadingModal(true);

        if (setInitiative == null) return;

        if ((window as any).analytics != null && (window as any).analytics.track != null) {
            (window as any).analytics.track('Initiative Created', {
                page: 'Initiative'
            });
        }

        let iType = initiativeType || appConfig.initiativeTypes[0];
        let createdInitiative: Initiative | null = null;

        if (generate) {
            // API call to generate an initiative
            createdInitiative = await api('/initiative/generate', { "description": initiativeInstructions }, initiative);
        } else if (templateId) {
            // Create initiative from a template
            const template = initiatives?.find((tmpl) => tmpl.id === templateId);

            if (template) {
                const clonedTemplate: Initiative = { ...template, id: undefined, template_uid: template.uid, uid: uuidv4(), template: false, name: initiativeName };
                createdInitiative = await api('/initiative/create', clonedTemplate, initiative);
            }
        } else {
            // Create a new initiative from scratch
            const newInitiative = {
                initiative_type: iType,
                routes: { assistants: [] },
                name: initiativeName,
                instructions: initiativeInstructions,
            };

            createdInitiative = await api('/initiative/create', newInitiative, initiative);
        }

        if (createdInitiative) {
            setInitiative(createdInitiative);
            window.dispatchEvent(new Event('initiativeChange'));
            window.dispatchEvent(new Event('initiativeCreated'));
        }

        setLoadingModal(false);
        onClose();
    }, [initiativeType, templateId, initiativeName, initiativeInstructions, initiatives, setInitiative, onClose, initiative]);

    const handleCancel = () => {
        onClose();
    };

    return (
        <IonModal className="form-modal" onDidDismiss={onClose} isOpen={show}>
            {!loadingModal ? (
                <div className="form">
                    {appConfig.initiativeTypes.length > 1 && (
                        <IonSelect
                            className='form-element'
                            label="Initiative type"
                            labelPlacement="floating"
                            interface="popover"
                            placeholder={appConfig.initiativeTypes[0]}
                            value={initiativeType}
                            onIonChange={e => setInitiativeType(e.detail.value!)}
                        >
                            {appConfig.initiativeTypes.map(type => (
                                <IonSelectOption key={type} value={type}>
                                    {type.charAt(0).toUpperCase() + type.slice(1)}
                                </IonSelectOption>
                            ))}
                        </IonSelect>
                    )}

                    <IonSelect
                        className='form-element'
                        label="Create on launchpad"
                        labelPlacement="floating"
                        interface="popover"
                        placeholder="Select launchpad ..."
                        value={templateId || ''}
                        onIonChange={e => setTemplateId(e.detail.value)}
                    >
                        {templates.map(template => (
                            <IonSelectOption key={template.id} value={template.id}>
                                {template.name}
                            </IonSelectOption>
                        ))}
                    </IonSelect>

                    <IonInput
                        className='form-element'
                        label="Name"
                        labelPlacement="floating"
                        fill="outline"
                        value={initiativeName}
                        placeholder="Name"
                        onIonChange={e => setInitiativeName(e.detail.value!)}
                    />

                    <IonTextarea
                        className='form-element'
                        label="Instructions"
                        labelPlacement="floating"
                        fill="outline"
                        rows={5}
                        value={initiativeInstructions}
                        onIonChange={e => setInitiativeInstructions(e.detail.value!)}
                        placeholder="Initiative Description"
                    />
                    <IonButton onClick={() => handleCreateInitiative(false)}>Create</IonButton>
                    <IonButton className="basic" onClick={handleCancel}>Cancel</IonButton>
                </div>
            ) : (
                <div className="form" style={{ minHeight: "160px" }}>
                    <div className="form-element-title" style={{ opacity: "0.5", width: "100%", textAlign: "center" }}>
                        <IonIcon icon={duplicate} style={{ width: "60px", height: "60px" }} className="menu" color={"medium"} />
                    </div>
                    <div className="form-element-title" style={{ opacity: "0.5", width: "100%", textAlign: "center" }}>
                        Preparing ...
                    </div>
                    <div className="form-element-title" style={{ width: "100%", textAlign: "center" }}>
                        <IonSpinner name="dots" color="dark" className="large-spinner" style={{ margin: "10px" }} />
                    </div>
                </div>
            )}
        </IonModal>
    );
};

export default CreateInitiative;
