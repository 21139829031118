import React from 'react';
import { PrismicContentType, Initiative, Prompt, User } from './Types';

interface GlobalContextType {

  token?: string | null;
  setToken: React.Dispatch<React.SetStateAction<string | null>>; 

  user?: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>; 

  users?: User[] | null;
  setUsers: React.Dispatch<React.SetStateAction<User[] | null>>; 

  initiatives?: Initiative[] | null;
  setInitiatives: React.Dispatch<React.SetStateAction<Initiative[] | null>>; 

  initiative?: Initiative;
  setInitiative: React.Dispatch<React.SetStateAction<Initiative | undefined>>; 

  getInitiative: (uid: string) => Initiative | undefined;

  prompts?: Prompt[] | null;
  setPrompts: React.Dispatch<React.SetStateAction<Prompt[]>>; 

  isDarkMode?: boolean | false;
  setIsDarkMode: React.Dispatch<React.SetStateAction<boolean | false>>;

  isMenuVisible?: boolean | false;
  setIsMenuVisible: React.Dispatch<React.SetStateAction<boolean | false>>;

  smallScreen?: boolean | false;
  setSmallScreen: React.Dispatch<React.SetStateAction<boolean | false>>;

  showMenu?: boolean | false;
  setShowMenu: React.Dispatch<React.SetStateAction<boolean | false>>;

  prismicContent?: PrismicContentType;
  setPrismicContent: React.Dispatch<React.SetStateAction<any | undefined>>;
}

const defaultContext: GlobalContextType = {

  token: null,
  setToken: () => null,

  user: null,
  setUser: () => null,

  users: null,
  setUsers: () => null,

  initiatives: null,
  setInitiatives: () => {},

  initiative: undefined,
  setInitiative: () => {},

  getInitiative: () => undefined,
  
  prompts: null,
  setPrompts: () => null,

  isDarkMode: true,
  setIsDarkMode: () => {},

  isMenuVisible: true,
  setIsMenuVisible: () => {},

  smallScreen: true,
  setSmallScreen: () => {},

  showMenu: true,
  setShowMenu: () => {},

  prismicContent: { navigation: undefined },
  setPrismicContent: () => {},

};

const GlobalContext = React.createContext<GlobalContextType>(defaultContext);

export default GlobalContext;
