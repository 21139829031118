import useAuth from './domain/Auth';

import { useEffect, useContext, useRef, useState } from 'react';
import {
  IonContent,
  IonButton,
  IonHeader,
  IonIcon,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonBreadcrumbs,
  IonBreadcrumb,
  IonSpinner,
  IonProgressBar
} from '@ionic/react';
import { useLocation } from 'react-router-dom';
import './theme/pages.css';
import './theme/menu.css';
import './theme/forms.css';
import { planet, menu, exitOutline, closeCircle } from 'ionicons/icons';
import GlobalContext from "./domain/GlobalContext";
import ListView from './components/ListView';
import { Initiative } from './domain/Types';
import { useHistory } from 'react-router';
import { appConfig } from './domain/Config';
import Home from './Home';

const AppContainer: React.FC<{ 
  ContentComponent: React.ComponentType,
  BreadcrumbComponent: React.ComponentType,
  MenuComponent: React.ComponentType,
}> = ({ ContentComponent, BreadcrumbComponent, MenuComponent }) => {

  const history = useHistory();
  const location = useLocation();  
  const { logout } = useAuth();
  const { user, initiatives, initiative, setInitiative, isDarkMode, setIsDarkMode, isMenuVisible, setIsMenuVisible, smallScreen } = useContext(GlobalContext);

  useEffect(() => {

    const clearUrl = async () => {
      history.push("/");
    };

    const urlParams = new URLSearchParams(window.location.search);
    const oobCode = urlParams.get('oobCode');
    if (oobCode) {
      clearUrl();
    }
  }, [history]);

  useEffect(() => {

    if (appConfig.display_mode && appConfig.display_mode == "dark") {

      document.body.classList.toggle('dark', true);
      setIsDarkMode(true);
    }
  }, []);

  useEffect(() => {
    if ((window as any).analytics != null && (window as any).analytics.track != null) {
      console.log("Track: "+ location.pathname +". Rendering: "+ ContentComponent.name);
      (window as any).analytics.track('Page View', {
        page: location.pathname // This gets the current page path
      });
    }
  }, [location]); // This effect will run every time the location/path changes

  // Function to toggle menu visibility
  const toggleMenu = () => {
    if (smallScreen) {
          setIsMenuVisible(!isMenuVisible);
    }
  };

  // Ref for the menu element
  const menuRef = useRef<HTMLDivElement>(null);

  // Function to check if the click is outside the menu
  const handleClickOutside = (event: { target: any; }) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      toggleMenu();
    }
  };

  useEffect(() => {
    // Add event listener when the menu is visible
    if (isMenuVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Cleanup the event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuVisible]); // Dependency on isMenuVisible

  return (   
    <div>
        <div id="web-screen" className="layout-component">
            <div id="main-menu" className={`menu-component ${isMenuVisible ? 'visible' : ''}`} ref={menuRef}>

            <IonContent>
                <MenuComponent />
            </IonContent>
                
            </div>
            <div id="wrapper-page" className={`page-component ${isMenuVisible ? 'menu-dimmed' : ''}`}>
 
            <IonHeader class="ion-no-border">
              <IonToolbar>
                <IonButtons slot="start" className="menu-toggle-button-container">
                  {smallScreen && (
                    <>
                      <IonButton onTouchStart={toggleMenu} onClick={toggleMenu} className="menu-toggle-button">
                        <IonIcon icon={menu} size="large" className="link" />
                      </IonButton>
                    </>
                )}
                </IonButtons>
                <IonTitle>
                  {(initiative || location.pathname == '/account' || location.pathname == '/prompts' || location.pathname == '/templates'  || location.pathname == '/plan') ? 
                  <BreadcrumbComponent /> 
                  :
                  <IonBreadcrumbs>
                    <IonBreadcrumb routerLink="/">Home</IonBreadcrumb>
                  </IonBreadcrumbs>
                  }
                </IonTitle>
                <IonIcon icon={exitOutline} onClick={() => logout()} slot="end" className="link" size="small" style={{ display: "block", paddingRight: "20px" }} />
              </IonToolbar>
            </IonHeader>

            <IonContent class="ion-no-border">
                <div className="content-container">
                  {(initiative || 
                        location.pathname == '/account' || 
                        location.pathname == '/plan' || 
                        location.pathname == '/prompts' || 
                        location.pathname == '/templates') ?
                    <ContentComponent /> : <Home /> 
                  }
              </div>
            </IonContent>

            </div>
        </div>
    </div>
  );
};

export default AppContainer;