import { IonBreadcrumbs, IonBreadcrumb, IonIcon } from '@ionic/react';
import AppContainer from '../../../AppContainer';
import { chatbubbleEllipses, diamond, folder } from 'ionicons/icons';
import Menu from '../../../components/Menu';
import { useContext, useEffect, useState } from 'react';
import GlobalContext from '../../../domain/GlobalContext';

interface Message {
  id: string;
  text: string;
  sender: string;
  timestamp: any;
}

const Page: React.FC = () => {

  const { initiative } = useContext(GlobalContext);  
  const [reportUrl, setReportUrl] = useState('');

  useEffect(() => {
    if (initiative?.name === 'www.meom.fi') {
      setReportUrl('https://lookerstudio.google.com/embed/reporting/12521109-bbca-4525-a865-9032a879ef58/page/p_23w0ow2jcd');
    } else if (initiative?.name === 'arciplug.com') {
      setReportUrl('https://lookerstudio.google.com/embed/reporting/4b6a74d3-7d6c-4226-b600-0937691c7e1f/page/p_266q8gt9jd');
    } else {
      setReportUrl('/placeholder.html');
   }    
}, [initiative]);

  return (
    <div>

      <iframe className="fullscreen" height="700" src={`${reportUrl}`}></iframe>

    </div>
  );
};

const Breadcrumb: React.FC = () => {
    return (
      <IonBreadcrumbs>
        <IonBreadcrumb routerLink="/">
          <IonIcon slot="start" icon={folder}></IonIcon>
          Web analytics
        </IonBreadcrumb>
      </IonBreadcrumbs>
    );
  };
  
  const Container: React.FC<{}> = () => { return <AppContainer ContentComponent={Page} BreadcrumbComponent={Breadcrumb} MenuComponent={Menu} />; };
  export default Container;