import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonIcon, IonInput } from '@ionic/react';
import { appConfig } from './domain/Config';
import { mailOutline } from 'ionicons/icons';
import { getAuth, signInWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import useAuth from './domain/Auth';
import GlobalContext from './domain/GlobalContext';
import { useContext, useEffect } from 'react';
import { api } from "./domain/ApiService";

const Verification: React.FC = () => {

  const { user } = useContext(GlobalContext);

  const { token, logout } = useAuth();

  const resendEmail = async (e: React.FormEvent) => {

    e.preventDefault();

    const auth = getAuth();

    try { 

        const firebaseUser = auth.currentUser;

        if (firebaseUser)
          await sendEmailVerification(firebaseUser);

    } catch (err) {

        // presentToast("Failed to sign in. Please check your credentials.");            
    }
  };

  return (

    <div className="centered-content content-container">

    <IonCard style={{cursor: 'pointer', padding: '20px', marginLeft: '0px'}}>
      <IonCardHeader>
        <IonCardTitle>
        <IonIcon icon={mailOutline} size="large" className="link" />
        </IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <h2 style={ { fontSize: "140%", fontWeight: "bold" } }>Email verification</h2>
        <br />
        <p style={ { fontSize: "120%", maxWidth: "400px", paddingBottom: "5px" } }>Kindly check your email to verify your address. Please click the link we've sent to proceed.</p>
        <p style={ { fontSize: "120%", maxWidth: "400px", paddingBottom: "5px" } }>If you haven't received it, please check your spam folder or reach out to our support for assistance.</p>
        <p style={ { fontSize: "120%", maxWidth: "400px", paddingBottom: "5px" } }>Thank you!</p>
        <br />
        <p style={ { fontSize: "120%", maxWidth: "400px", paddingBottom: "5px" } }><a onClick={logout}>Logout</a> or <a onClick={resendEmail}>resend an email</a>.</p>                
      </IonCardContent>
    </IonCard>

    </div>

  );
};

export default Verification;