import { IonBreadcrumbs, IonBreadcrumb, IonIcon, IonCard, IonCardTitle, IonCardHeader, IonButton, IonCardContent, IonInput, IonModal, IonTextarea, IonProgressBar, ItemReorderEventDetail, IonSelect, IonSelectOption, IonSpinner } from '@ionic/react';
import AppContainer from '../AppContainer';
import { folder, duplicate } from 'ionicons/icons';
import Menu from '../components/Menu';
import { useHistory } from 'react-router';
import { useCallback, useContext, useEffect, useState } from 'react';
import GlobalContext from '../domain/GlobalContext';
import { api } from '../domain/ApiService';
import { Initiative } from '../domain/Types';
import ListView from '../components/ListView';
import { appConfig } from '../domain/Config';
import { v4 as uuidv4 } from 'uuid';
import useAuth from '../domain/Auth';

const Page: React.FC = () => {

  const history = useHistory();

  const { user, initiative, initiatives, setInitiative } = useContext(GlobalContext);

  const [showModal, setShowModal] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);

  const [ template, setTemplate ] = useState<Initiative | null>(null);
  const [ templates, setTemplates ] = useState<Initiative[]>([]);

  useEffect(() => {

      if (initiatives != null) {

        let filtered = initiatives.filter(initiative => {
            return initiative.template ? initiative.template : false;
        });
    
        setTemplates(filtered);
      }

  }, [initiatives]);

  const handleEdit = (clickedTemplate: Initiative | null) => {

    if (clickedTemplate == null) {

      let newTemplate = { 
        uid: uuidv4(),
        initiative_type: appConfig.initiativeTypes[0], 
        routes: { assistants: [] }, 
        template: true,
        name: "",
        slug: "",
        instructions: "" 
      };

      setTemplate(newTemplate);
      setShowModal(true);
    }
    else {

      setInitiative(clickedTemplate);
      setShowModal(false);
      history.push('/');
    }
  };

  const handleReorder = (event: CustomEvent<ItemReorderEventDetail>) => {
    console.log(JSON.stringify(event));
  }; 

  const handleCancel = () => {

    setShowModal(false);
    setTemplate(null);
  };

  const handleClick = (clickedTemplate: Initiative) => {

    setInitiative(clickedTemplate);
    setShowModal(false);
    history.push('/');
  };

  const handleCreateInitiative = useCallback(async (generate: boolean) => {

    setLoadingModal(true);
    if (setInitiative == null || template == null) return;

    let iType = template?.initiative_type;

    if (iType == null || iType.trim() == "") {
        iType = appConfig.initiativeTypes[0];
    }

    if (generate) {

        const createdInitiative: Initiative = await api('/initiative/generate', {"description": template?.instructions }, initiative);        
        setInitiative(createdInitiative);
    }
    else {

        let newInitiative = { 
            template: true,
            initiative_type: iType, 
            routes: { assistants: [] }, 
            name: template.name,
            instructions: template.instructions 
        };

        const createdInitiative: Initiative = await api('/initiative/create', newInitiative, initiative);        
        setInitiative(createdInitiative);
    }

    setShowModal(false);
    setLoadingModal(false);

    history.push('/');

    window.dispatchEvent(new Event('initiativeChange'));
    window.dispatchEvent(new Event('initiativeCreated'));

  }, [template, initiative, setInitiative, history]);


  return (
    <>
      <IonCard className='filled' style={{ padding: "10px" }}>
        <IonCardHeader>
          <IonCardTitle>Launchpads</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>

          <ListView<Initiative>
                    items={templates}
                    columns={[
                      { attribute: 'name', label: 'Name', size: "small", primary: true },
                      { attribute: 'instructions', label: 'Instructions', size: "small" }
                    ]}
                    enableReorder={false}
                    defaultLayout={'cards'}
                    showActions={user?.role == 'admin' ? true : false}
                    onEdit={handleEdit}                    
                    onClick={handleClick}
                    onReorder={handleReorder}
                    />

        </IonCardContent>
      </IonCard>

      <IonModal className="form-modal" onDidDismiss={() => setShowModal(false)} isOpen={showModal}>
        {(!loadingModal && template) ? 
            <div className="form">

                {appConfig.initiativeTypes.length > 1 ? (
                    <IonSelect className='form-element' 
                        label="Initiative type" 
                        labelPlacement="floating" 
                        interface="popover" 
                        placeholder="Select type ..."
                        defaultValue={template.initiative_type != null ? template.initiative_type : ""}
                        value={template.initiative_type != null ? template.initiative_type : ""}
                        onIonChange={e => template.initiative_type = e.detail.value}>
                        {appConfig.initiativeTypes.map(type => (
                            <IonSelectOption key={type} value={type}>
                                {type.charAt(0).toUpperCase() + type.slice(1)}
                            </IonSelectOption>
                        ))}

                    </IonSelect>
                ) : null}

                <IonInput 
                    className='form-element'
                    label="Name"
                    labelPlacement="floating"
                    fill="outline"
                    value={template.name} 
                    placeholder="Name"                    
                    onIonChange={e => template.name = e.detail.value!} 
                />

                <IonTextarea 
                    className='form-element'
                    label="Instructions"
                    labelPlacement="floating"
                    fill="outline"
                    rows={5}            
                    value={template.instructions}
                    onIonChange={e => template.instructions = e.detail.value!} 
                    placeholder="Instructions"
                />                    
                {(appConfig.initiativeTypes.length !== 1 || appConfig.initiativeTypes[0] !== 'client') ? 
                <>
                <IonButton onClick={() => handleCreateInitiative(true)}>Generate launchpad</IonButton>
                <IonButton className="basic" onClick={() => handleCreateInitiative(false)}>Create empty launchpad</IonButton>
                </>
                : 
                <IonButton onClick={() => handleCreateInitiative(false)}>Create template</IonButton>
                }


                <IonButton className="basic" onClick={handleCancel}>Cancel</IonButton>

            </div>
        :
            <div className="form" style={ { minHeight: "160px" } }>
            <div className="form-element-title" style={{ opacity: "0.5", width: "100%", textAlign: "center" }}>                  
                <IonIcon icon={ duplicate } style={{ width: "60px", height: "60px" }} className="menu" color={ "medium" } />            
            </div>
            <div className="form-element-title" style={{ opacity: "0.5", width: "100%", textAlign: "center" }}>
                Preparing ...
            </div>
            <div className="form-element-title" style={{ width: "100%", textAlign: "center" }}>
                <IonSpinner name="dots" color="dark" class="large-spinner" style={{margin: "10px"}}></IonSpinner>
            </div>
            </div>
        }
        </IonModal>

    </>
  );
};

const Breadcrumb: React.FC = () => {
    return (
      <IonBreadcrumbs>
        <IonBreadcrumb routerLink="/templates">
          <IonIcon slot="start" icon={folder}></IonIcon>
          Templates
        </IonBreadcrumb>
      </IonBreadcrumbs>
    );
  };
  
const Container: React.FC<{}> = () => { return <AppContainer ContentComponent={Page} BreadcrumbComponent={Breadcrumb} MenuComponent={Menu} />; };
export default Container;
