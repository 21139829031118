import useAuth from '../domain/Auth';

import { useState, useEffect, useContext, useRef, useCallback, ChangeEvent } from 'react';
import {
  IonSpinner,
  IonButton,
  IonIcon,
  IonInput,
  IonItem,
  IonList,
  IonModal,
  IonAvatar,
  IonLabel,
  IonTextarea,
  IonPopover,
  IonSelect,
  IonSelectOption
} from '@ionic/react';
import { NavLink, useLocation, useHistory } from 'react-router-dom';
import '../theme/pages.css';
import '../theme/menu.css';
import '../theme/forms.css';
import { card, sunny, moon, chevronDownOutline, settings, bookmarks, filter, crop, server, duplicate, checkbox, close, closeCircle } from 'ionicons/icons';
import GlobalContext from "../domain/GlobalContext";
import { api } from "../domain/ApiService";
import { Initiative, NestedRoute } from "../domain/Types";
import { PrismicRichText, useSinglePrismicDocument } from "@prismicio/react";
import routes from "../domain/Routes"
import React from 'react';
import generateRoutes from '../domain/Routes';
import { appConfig } from '../domain/Config';
import { v4 as uuidv4 } from 'uuid';
import NestedNavigation from './NestedNavigation';

interface MenuProps {
    NavigationComponent?: React.ComponentType<any> | null;
}

const Menu: React.FC<MenuProps> = ({ NavigationComponent }) => {

    const location = useLocation();  
    const history = useHistory();
    const [showModal, setShowModal] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);
    const [initiativeType, setInitiativeType] = useState<string>(appConfig.initiativeTypes[0]);
    const [initiativeName, setInitiativeName] = useState('');
    const [initiativeInstructions, setInitiativeInstructions] = useState('');
    const { user,
            initiative, 
            setInitiative, 
            initiatives, 
            prismicContent, 
            setPrismicContent, 
            smallScreen, 
            setSmallScreen } = useContext(GlobalContext);    
    const { isDarkMode, setIsDarkMode } = useContext(GlobalContext);
    const [filteredInitiatives, setFilteredInitiatives] = useState<Initiative[]>([]);

    const [templates, setTemplates] = useState<Initiative[]>([]);
    const [templateId, setTemplateId] = useState<number | null>(null);

    useEffect(() => {

        if (initiatives != null) {
  
          let filtered = initiatives.filter(initiative => {
              return initiative.template ? initiative.template : false;
          });
      
          setTemplates(filtered);
        }
  
    }, [initiatives]);

    // Specify the type of the ref as ResizeObserver | null
    const resizeObserverRef = useRef<ResizeObserver | null>(null);

    const toggleSmallScreen = () => {
        const width = document.body.clientWidth;
        if (width >= 1000) {
            setSmallScreen(false)
        } else {
            setSmallScreen(true)
        }
    };

    useEffect(() => {

    const prefersDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    setIsDarkMode(prefersDarkMode);
    document.body.classList.toggle('dark', prefersDarkMode);

    const resizeObserver = new ResizeObserver(toggleSmallScreen);
    resizeObserver.observe(document.body);

    resizeObserverRef.current = resizeObserver;

    return () => {
        if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect();
        }
    };    
    }, []);

    const toggleLight = useCallback(() => {
    const isDark = !isDarkMode;
    if (setIsDarkMode)
        setIsDarkMode(isDark);
    document.body.classList.toggle('dark', isDark);
    }, [isDarkMode, setIsDarkMode]);

    const handleCreateInitiative = useCallback(async (generate: boolean) => {

        setLoadingModal(true);
        if (setInitiative == null) return;

        if ((window as any).analytics != null && (window as any).analytics.track != null) {
            (window as any).analytics.track('Initiative Created', {
            page: 'Initiative'
            });
        }

        let iType = initiativeType;

        if (iType == null || iType.trim() == "") {
            iType = appConfig.initiativeTypes[0];
        }


        if (generate) {

            const createdInitiative: Initiative = await api('/initiative/generate', {"description": initiativeInstructions }, initiative);        
            setInitiative(createdInitiative);
        }
        else if (templateId) {

            const template: Initiative | undefined = templates.find(template => template.id === templateId);

            if (template) {

                const clonedTemplate: Initiative = JSON.parse(JSON.stringify(template));
                
                clonedTemplate.id = undefined;
                clonedTemplate.template_uid = clonedTemplate.uid;
                clonedTemplate.uid = uuidv4();
                clonedTemplate.template = false;
                clonedTemplate.name = initiativeName;
                // clonedTemplate.instructions = initiativeInstructions;            
                const createdInitiative: Initiative = await api('/initiative/create', clonedTemplate, initiative);        
                setInitiative(createdInitiative);
            }
        } 
        else {

            let newInitiative = { 
                initiative_type: iType, 
                routes: { assistants: [] }, 
                name: initiativeName,
                instructions: initiativeInstructions 
            };

            const createdInitiative: Initiative = await api('/initiative/create', newInitiative, initiative);        
            setInitiative(createdInitiative);
        }

        setShowModal(false);
        setLoadingModal(false);

        history.push('/');

        window.dispatchEvent(new Event('initiativeChange'));
        window.dispatchEvent(new Event('initiativeCreated'));

    }, [initiativeType, templateId, initiativeName, initiativeInstructions, initiative, setInitiative, history]);

    useEffect(() => {
        
        if (initiatives != null) {

            let filtered = initiatives.filter(initiative => {
                if (user?.auth_id && initiative && !initiative.template) {

                    const isOwnerOrCollaborator = !initiative.owners || !initiative.collaborators || initiative.owners.includes(user.auth_id) || initiative.collaborators.includes(user.auth_id);
                    const isCollaboratorOrAllUsers = !initiative.collaborators || initiative.collaborators.includes(user.auth_id) || initiative.collaborators.length === 0 || initiative.collaborators.includes("allUsers");
                    return isOwnerOrCollaborator || isCollaboratorOrAllUsers;
                }
                return false;
            });
            
            setFilteredInitiatives(filtered);

            let domainInitiatives: Initiative[] = [];
            const domain_array = ["laurea", "oi.fi", "nbforum", "staffpoint", "arci"];

            if (user?.email) {
                domain_array.forEach(domain => {
                    if (user.email && user.email.includes(domain)) {
                        const filteredInitiatives = initiatives.filter(initiative => {
                            const initiativeNameLower = (initiative.name != null ? "" + initiative.name : "").toLowerCase();
                            return initiativeNameLower.includes(domain);
                        });
                        domainInitiatives = domainInitiatives.concat(filteredInitiatives);
                    }
                });
            }
            
            if (domainInitiatives.length == 1 && !initiative) {

                const selectedInitiative = domainInitiatives[0];
                setInitiative(selectedInitiative);
                window.dispatchEvent(new Event('initiativeChange'));
                history.push("/");
            }
        }            
    }, [initiatives]);

    const handleInitiativeChange = useCallback((e: any) => {
        
        setPopoverState({ showPopover: false, event: null });

        if (!e) {

            setShowModal(true);
            return;
        } 
        else {

            const initiativeId = e.id;
            const selectedInitiative = initiatives?.find(initiative => initiative.id === initiativeId);

            if (selectedInitiative) {

                setInitiative(selectedInitiative);

                if (selectedInitiative)
                    history.push("/");
    
                window.dispatchEvent(new Event('initiativeChange'));

            } else {
                console.log("Initiative not found");
            }
        }

        if (initiatives != null) {
            let filtered = initiatives.filter(initiative => {
                return !initiative.template;
            });
        
            setFilteredInitiatives(filtered);
        }            

    }, [initiatives, setInitiative, history]);

    const handleCancel = () => {

        setShowModal(false);
    };

    const handleCloseInitiative = () => {

        setShowModal(false);
        setInitiative(undefined);
        history.push("/");
    };

    function isActive(path: string) {

    return location.pathname === path;
    }

    type PopoverState = {
    showPopover: boolean;
    event: MouseEvent | null;
    };

    const mainElementRef = useRef(null);
    const [popoverState, setPopoverState] = useState<PopoverState>({ showPopover: false, event: null });

    const togglePopover = (originalEvent: React.MouseEvent) => {
    const mainElement = document.getElementById('main-ionitem');
    if (mainElement) {
        const event = new MouseEvent('click', { bubbles: true, cancelable: true, view: window });
        Object.defineProperty(event, 'target', { writable: false, value: mainElement });
        setPopoverState({ showPopover: !popoverState.showPopover, event: event });
    }
    };

    const [isMenuVisible, setIsMenuVisible] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Function to toggle menu visibility
    const hideMenu = () => {
        if (smallScreen) {
            setIsMenuVisible(false);
        }
    };

    // Listen for window resize to adjust menu visibility
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Hide menu when clicking outside of it on small screens
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
        if (windowWidth < 1000 && isMenuVisible && !document.getElementById("main-menu")?.contains(event.target as Node)) {
            setIsMenuVisible(false);
        }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [windowWidth, isMenuVisible]);

    const [prismicNavi] = useSinglePrismicDocument('navigation');

    useEffect(() => {

    if (prismicNavi && prismicNavi.data != undefined) {

        setPrismicContent({
        navigation: prismicNavi.data
        });
    }
    }, [prismicNavi]);

    const handleSearchChange = (event: CustomEvent<{ value?: string | null }>) => {

        if (initiatives == null) return;

        const searchText = event.detail.value?.toLowerCase() || '';

        let filtered = initiatives.filter(initiative => {
            const initiativeNameLower = (initiative.name != null ? "" + initiative.name : "").toLowerCase();
            return initiativeNameLower.includes(searchText);
        });

        filtered = filtered.filter(initiative => {
            return !initiative.template;
        });

        setFilteredInitiatives(filtered);
    };

    const handleCloseTemplate = () => {

        setInitiative(undefined);
        history.push("/templates");
    };
    
    return (   

    <div className="menu">

    <IonList className="navigation transparent menu-sticky-top">
        <IonItem color="none" lines="none">
        <img alt="" src="/assets/icon/favicon.png" className="logo" onClick={() => handleCloseInitiative()} />
        </IonItem>

        {(initiative?.template ?
            <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                <IonButton onClick={() => handleCloseTemplate()} style={{marginLeft: "12px" }}>
                    <IonIcon icon={closeCircle} style={{ position: "relative", left: "-5px", top: "0px", width: "20px", height: "20px" }} size="small" color="light" />
                    Template
                </IonButton>
            </div>
        : 
        <>
        <IonItem id="main-ionitem" color="none" lines="none" onClick={togglePopover} className="link menu-sub" style={{ marginBottom: "10px" }}>
            <span style={{ maxWidth: "170px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{initiative ? initiative.name : "Select" }</span>
            <IonIcon icon={chevronDownOutline} style={{ position: "relative", left: "10px", top: "1px" }} size="small" color="medium" />
        </IonItem>
        <IonPopover
        className="transparent initiative"
        isOpen={popoverState.showPopover}
        event={popoverState.event}
        onDidDismiss={() => setPopoverState({ showPopover: false, event: null })}
        >
        <div className="filled initiative-menu">
            <div className="initiative-list">
            <IonInput 
                className='form-element'
                style={{width: "90%", marginLeft: "10px"}}
                label="Search"
                labelPlacement="floating"
                fill="outline"
                placeholder="Search"
                onIonInput={handleSearchChange}
            />

            {filteredInitiatives?.map(initiative => (
            <div className="initiative-item" key={initiative.id} onClick={() => handleInitiativeChange(initiative)}>
                <div className="link initiative-item-button">
                {initiative.name}
                </div>
            </div>
            ))}

            <div className="initiative-settings" key="-1" onClick={() => handleInitiativeChange(undefined)}>
                <div className="link initiative-settings-button">
                <IonIcon icon={settings} className="menu" color="medium" style={{ position: "relative", width: "16px", height: "16px", top: "2px", left: "2px" }} />
                <span>Create new</span>
                </div>
            </div>
            </div>
        </div>
        </IonPopover>
        </>)}
        {NavigationComponent ?         
            <NavigationComponent />
            :
            <>
            {(initiative != null) ?
                <NestedNavigation nestedRoutes={generateRoutes(initiative)!} basePath={"/"+ initiative?.name} /> : <></>
            }
            </>
        }

    </IonList>

    <IonList className="navigation transparent menu-sticky-bottom">
        {user?.role == "admin" && (appConfig.initiativeTypes.length !== 1 || (appConfig.initiativeTypes[0] !== 'content' && appConfig.initiativeTypes[0] !== 'script')) ? 
        <>
            <NavLink onClick={toggleSmallScreen} to="/templates" className="menu"><IonItem color="none" lines="none" className={(location.pathname.startsWith("/templates")) ? "navigation-selected" : ""}><IonIcon icon={duplicate} className="menu" color="medium" /><span className="menu-sub">Launchpads</span></IonItem></NavLink>
            {(appConfig.stripe_enabled) ? 
            <>
                <NavLink onClick={toggleSmallScreen} to="/plan" className="menu"><IonItem color="none" lines="none" className={(location.pathname.startsWith("/plan")) ? "navigation-selected" : ""}><IonIcon icon={card} className="menu" color="medium" /><span className="menu-sub">Billing</span></IonItem></NavLink>
            </>
            : <></>}
            <NavLink onClick={toggleSmallScreen} to="/prompts" className="menu"><IonItem color="none" lines="none" className={(location.pathname.startsWith("/prompts")) ? "navigation-selected" : ""}><IonIcon icon={bookmarks} className="menu" color="medium" /><span className="menu-sub">Prompts</span></IonItem></NavLink>
        </>
        : <></> }
        {/*
        <IonItem color="none" lines="none" onClick={toggleLight} className="link navigation">
        
            {isDarkMode ? 
                <>
                <IonIcon icon={ sunny } className="menu" color={ "warning" } />
                <span className="menu-sub">Light mode</span>
                </>                  
            :
                <>
                <>
                <IonIcon icon={ moon } className="menu" color={ "medium" } />
                <span className="menu-sub">Dark mode</span>
                </>
                </>          
            }
        </IonItem>
        */}
        <IonItem onClick={toggleSmallScreen} color="none" lines="none" className="link" routerLink="/account">
        <IonAvatar className="menu-avatar">
            <img alt="" src={user != null && user.avatar != null ? user.avatar : "/assets/avatar.jpg" } />
        </IonAvatar>
        <IonLabel className="menu-avatar">
            <span style={{ paddingLeft: "10px", opacity: "0.7", fontSize: "90%" }}>User</span><br />              
            <span style={{ paddingLeft: "10px", fontWeight: "500" }}>Account</span><br />              
        </IonLabel>
        </IonItem>            
    </IonList>
    <IonModal className="form-modal" onDidDismiss={() => setShowModal(false)} isOpen={showModal}>
    {(!loadingModal) ? 
        <div className="form">

            {appConfig.initiativeTypes.length > 1 ? (
                <IonSelect className='form-element' 
                    label="Initiative type" 
                    labelPlacement="floating" 
                    interface="popover" 
                    placeholder={appConfig.initiativeTypes[0]}
                    defaultValue={appConfig.initiativeTypes[0]}
                    value={initiativeType}
                    onIonChange={e => setInitiativeType(e.detail.value)}>
                    {appConfig.initiativeTypes.map(type => (
                        <IonSelectOption key={type} value={type}>
                            {type.charAt(0).toUpperCase() + type.slice(1)}
                        </IonSelectOption>
                    ))}

                </IonSelect>
            ) : null}

            <IonSelect className='form-element' 
                    label="Create on launchpad" 
                    labelPlacement="floating" 
                    interface="popover" 
                    placeholder="Select launchpad ..."
                    defaultValue={""}
                    onIonChange={e => setTemplateId(e.detail.value)}>

                    {templates.map(template => (
                        <IonSelectOption key={template.id} value={template.id}>
                            {template.name}
                        </IonSelectOption>
                    ))}

            </IonSelect>

            <IonInput 
                className='form-element'
                label="Name"
                labelPlacement="floating"
                fill="outline"
                value={initiativeName} 
                placeholder="Name"                    
                onIonChange={e => setInitiativeName(e.detail.value!)} 
            />

            <IonTextarea 
                className='form-element'
                label="Instructions"
                labelPlacement="floating"
                fill="outline"
                rows={5}            
                value={initiativeInstructions}
                onIonChange={e => setInitiativeInstructions(e.detail.value!)} 
                placeholder="Initiative Description"
            />                    
            <IonButton onClick={() => handleCreateInitiative(false)}>Create</IonButton>
            <IonButton className="basic" onClick={handleCancel}>Cancel</IonButton>

        </div>
    :
        <div className="form" style={ { minHeight: "160px" } }>
        <div className="form-element-title" style={{ opacity: "0.5", width: "100%", textAlign: "center" }}>                  
            <IonIcon icon={ duplicate } style={{ width: "60px", height: "60px" }} className="menu" color={ "medium" } />            
        </div>
        <div className="form-element-title" style={{ opacity: "0.5", width: "100%", textAlign: "center" }}>
            Preparing ...
        </div>
        <div className="form-element-title" style={{ width: "100%", textAlign: "center" }}>
            <IonSpinner name="dots" color="dark" class="large-spinner" style={{margin: "10px"}}></IonSpinner>
        </div>
        </div>
    }
    </IonModal>
    </div>
    );
};

export default Menu;