import { Initiative } from './Types';
import { appConfig} from './Config'
import { EndpointTypeMapping } from './Endpoints';

export async function api<T extends keyof EndpointTypeMapping>(endpoint: T, payload: object, selectedInitiative: Initiative | undefined): Promise<EndpointTypeMapping[T]> {

    try {
        
        console.log(endpoint)
        const accessToken = (window as any).accessToken
        const initiativeId = (selectedInitiative ? selectedInitiative.id : null);
        const auth0 = appConfig.auth0 == true ? "auth0=true" : "";
        
        const response = await fetch(`/functions${endpoint}?initiative=${initiativeId}&token=${accessToken}&${auth0}`, {

            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            
            body: JSON.stringify(payload),
        });

        if (!response.ok) {
            throw new Error(`Failed to fetch from ${endpoint}`);
        }

        const data: EndpointTypeMapping[T] = await response.json();
        return data;
    } 
    catch (err) {

        console.error(`Error in ApiService - ${endpoint}:`, err);
        throw err; // Throw the error to let the caller handle it
    }
}