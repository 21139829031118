import React, { useContext, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { IonItem, IonIcon } from '@ionic/react';
import GlobalContext from '../domain/GlobalContext';
import { NestedRoute } from "../domain/Types";
import { PrismicRichText } from "@prismicio/react";

const truncateLabel = (label: string, maxLength: number): string => {
    if (label.length > maxLength) {
        return `${label.substring(0, maxLength)}...`;
    }
    return label;
};

interface NestedNavigationProps {
    nestedRoutes: NestedRoute[];
    basePath?: string;
    indent?: number;
}

const NestedNavigation: React.FC<NestedNavigationProps> = ({ nestedRoutes, basePath = '', indent = 0 }) => {
    const { prismicContent } = useContext(GlobalContext);
    const [openRoutes, setOpenRoutes] = useState<Record<string, boolean>>({});
    const history = useHistory();
    
    const toggleOpen = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, fullPath: string) => {
        event.preventDefault();
        
        const windowPath = window.location.pathname.replaceAll("%20", " ");
    
        setOpenRoutes(currentOpenRoutes => {
            const newState: Record<string, boolean> = {};
    
            // Clear all other paths
            Object.keys(currentOpenRoutes).forEach(key => {
                newState[key] = false;
            });
    
            if (currentOpenRoutes[fullPath] && windowPath === fullPath) {
                // If the folder is currently open and the path matches exactly, close it
                newState[fullPath] = false;
            } else {
                // Open the clicked path and navigate if not already there
                newState[fullPath] = true;
                if (windowPath !== fullPath) {
                    history.push(fullPath);
                }
            }
    
            return newState;
        });
    };
    
    
    
    return (
        <>
            {nestedRoutes.map((route) => {
                let fullPath = basePath ? `${basePath}${route.path}` : route.path;
                let windowPath = window.location.pathname.replaceAll("%20", " ");
                const rootPath = `/${fullPath.split('/')[1]}`;
                const shouldRender = route.menu != null && route.menu === true;
                let isRouteOpen = !!openRoutes[fullPath];
    
                // Check if the current window path includes this route's full path
                if (windowPath.includes(fullPath)) {
                    // Check if this route is already considered open, toggle based on current state
                    if (openRoutes[fullPath] && windowPath === fullPath) {
                        isRouteOpen = false; // Toggle close if it was open and paths match exactly
                    } else {
                        isRouteOpen = true; // Ensure it's open otherwise
                    }
                }
    
                const hasChildren = route.children && route.children.length > 0;
    
                return shouldRender && (
                    <React.Fragment key={route.path}>
                        <div onClick={(event) => toggleOpen(event, fullPath)}
                            style={{ cursor: 'pointer' }}>
                            <IonItem color="none" lines="none" className={
                                windowPath === fullPath ? "navigation-selected" : indent === 0 && route.label !== "Client overview" ? "navigation-root" : ""} style={{ marginLeft: `${indent}px` }}>
                                {route.icon && (
                                    <IonIcon icon={isRouteOpen && route.iconOpen ? route.iconOpen : route.icon} className="menu" color="medium" />
                                )}
                                <span className="menu-main">
                                    {prismicContent?.navigation && prismicContent.navigation[route.label] ? (
                                        <PrismicRichText field={prismicContent.navigation[route.label]} />
                                    ) : (
                                        truncateLabel(route.label, 19)
                                    )}
                                </span>
                            </IonItem>
                        </div>
                        {hasChildren && isRouteOpen && route.children && (
                            <NestedNavigation nestedRoutes={route.children} basePath={fullPath} indent={indent + 10} />
                        )}
                    </React.Fragment>
                );
            })}
        </>
    );    
};

export default NestedNavigation;