import { IonBreadcrumbs, IonBreadcrumb, IonIcon } from '@ionic/react';
import AppContainer from '../../../AppContainer';
import { chatbubbleEllipses, diamond, folder } from 'ionicons/icons';
import Menu from '../../../components/Menu';
import GlobalContext from '../../../domain/GlobalContext';
import { useContext } from 'react';

interface Message {
  id: string;
  text: string;
  sender: string;
  timestamp: any;
}

const Page: React.FC = () => {

  const { initiative } = useContext(GlobalContext);

  return (
    <div>      
      <iframe name="generator" className="fullscreen border_bottom" height="700" src={`https://europe-west3-ai-analytics-387514.cloudfunctions.net/ai-analytics-prompt/index?ui=1&type=ideas&domain=${initiative?.name}`}></iframe>
    </div>
  );
};

const Breadcrumb: React.FC = () => {
    return (
      <IonBreadcrumbs>
        <IonBreadcrumb routerLink="/chat">
          <IonIcon slot="start" icon={folder}></IonIcon>
          Generate ideas
        </IonBreadcrumb>
      </IonBreadcrumbs>
    );
  };
  
  const Container: React.FC<{}> = () => { return <AppContainer ContentComponent={Page} BreadcrumbComponent={Breadcrumb} MenuComponent={Menu} />; };
  export default Container;