import AppContainer from '../AppContainer';
import { useCallback, useContext, useEffect, useState } from 'react';
import {
  IonInput,
  IonButton,
  IonSpinner,
  IonContent,
  IonPage,
  IonBreadcrumb,
  IonBreadcrumbs,
  IonRouterLink,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonProgressBar
} from '@ionic/react';
import { api } from '../domain/ApiService';
import useAuth from '../domain/Auth';
import React from 'react';
import Menu from '../components/Menu';
import GlobalContext from '../domain/GlobalContext';
import { User } from '../domain/Types';
import { v4 as uuidv4 } from 'uuid';
import ListView from '../components/ListView';
import { appConfig } from '../domain/Config';

const Page: React.FC = () => {

  const { isAuthenticated, isLoading } = useAuth();
  const [ key, setKey ] = useState('');
  const [ isKeyLoading, setIsKeyLoading ] = useState(false);
  const { user, initiative, users, setUsers } = useContext(GlobalContext);
  const [ isFetched, setIsFetched ] = useState(false);

  const [ showAccountModal, setShowAccountModal ] = useState(false);
  const [ selectedAccount, setSelectedAccount ] = useState<User | null>(null);
  const [ accountEmail, setAccountEmai ] = useState<string | null>(null);
  const [ accountPass, setAccountPass ] = useState<string | null>(null);

  const listInstanceAccounts = useCallback(async () => {
    try {
      const response = await api("/user/list", {}, initiative);
      setUsers(response);
    } catch (err) {
      console.error('Failed to fetch initiatives:', (err as Error).message);
    }
  }, []);

  useEffect(() => {

    if (user)
       listInstanceAccounts();
  }, [user]);

  const createAccount = async () => {
    if (!selectedAccount) return;
    setIsFetched(false);

    let createdAccount = (selectedAccount as any);

    if (accountEmail)
      createdAccount.email = accountEmail;

    if (accountPass)
      createdAccount["password"] = accountPass;

    console.log("PASS "+ accountPass)
    console.log(JSON.stringify(createdAccount))
    const response = await api("/user/create", createdAccount, initiative) as User; 
    setSelectedAccount(null);
    setAccountPass(null);
    setAccountEmai(null);
    listInstanceAccounts(); 
    setShowAccountModal(false);
    setIsFetched(true);
  };

  const updateAccount = async () => {
    if (!selectedAccount) return;
    setIsFetched(false);
    setUsers([]);
    setShowAccountModal(false);
    const response = await api("/user/update", selectedAccount, initiative) as User; 
    listInstanceAccounts();
    if (user && selectedAccount?.auth_id === user?.auth_id) {
      user.name = selectedAccount.name; 
    }
    setIsFetched(true);
  };

  const removeAccount = async () => {
    if (!selectedAccount || (user && selectedAccount?.auth_id === user?.auth_id)) return;
    setIsFetched(false);
    const response = await api("/user/remove", selectedAccount, initiative) as User; 
    listInstanceAccounts();
    setShowAccountModal(false);
    setIsFetched(true);
  };

  const handleAccountEdit = (item: User | null) => {

    console.log(JSON.stringify(item));
    let editItem = item;
    if (user != null && editItem == null) {
      editItem = {  
        "uid": uuidv4(), 
        "name": "", 
        "role": "user", 
        "dark_mode": null, 
        "instance_id": user.instance_id 
      }
    }
    setSelectedAccount(editItem);
    setShowAccountModal(true);
  };

  const handleAddKey = async () => {

    setIsKeyLoading(true);

    if ((window as any).analytics != null && (window as any).analytics.track != null) {

      (window as any).analytics.track('OpenAI API key added', {
        page: 'Account'
      });
    }

    await api('/user/secrets/create', { key: key }, undefined);
    setIsKeyLoading(false);
  };

  const handleRemoveKey = async () => {

    setIsKeyLoading(true);
    if ((window as any).analytics != null && (window as any).analytics.track != null) {

      (window as any).analytics.track('OpenAI API key removed', {
        page: 'Account'
      });
    }

    await api('/user/secrets/create', { key: "" }, undefined);
    setIsKeyLoading(false);
  };

  return (

    <div>

      <IonCard className='filled' style={{ padding: "10px" }}>
        <IonCardHeader>
          <IonCardTitle>Account</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          {/*
          <div className='form-element-text'>
            See also <IonRouterLink routerLink='/plan'>usage & billing</IonRouterLink>.
          </div>
          <div className='form-element-text'>
            You can configure your profile picture at <a href='https://gravatar.com/' target='_blank'>Gravatar</a>.
          </div>
          */}
          <IonInput
            className='form-element'
            label="Name"
            labelPlacement="floating"
            fill="outline"
            value={user?.name}
            placeholder="Name"
          />
          <IonInput
            className='form-element'
            label="Email"
            labelPlacement="floating"
            fill="outline"
            value={user?.email}
            placeholder="Email"
            disabled
          />
          <div className='form-element'>
            <IonButton disabled>
              Update 
            </IonButton>
          </div>
          </IonCardContent>
      </IonCard>

      {user?.role == "admin" && appConfig.initiativeTypes[0] == "launchpad" && (
      <IonCard className='filled' style={{ padding: "10px" }}>
          <IonCardHeader>
            <IonCardTitle>Account</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <div className='form-element-text'>
                By using your own keys the AI token limit is ingored.
            </div>          

                {
                  (isKeyLoading || (user && user.openai)) ? (
                    <>
                      <IonInput
                        disabled
                        className='form-element'
                        label="OpenAI API key"
                        labelPlacement="floating"
                        fill="outline"
                        value="******************"
                        placeholder="OpenAI API key"
                        onIonChange={e => setKey(e.detail.value!)} 
                        />
                      <div className='form-element'>

                        
                        {isKeyLoading ? 
                          (<IonSpinner name="dots" color="dark" class="large-spinner" style={{margin: "20px", marginTop: "10px"}}></IonSpinner>) : 
                          (<IonButton color="danger" disabled={isKeyLoading} onClick={handleRemoveKey}>Remove</IonButton>)
                        }
                      </div>
                    </>
                  ) : (
                    <>
                      <IonInput
                        className='form-element'
                        label="OpenAI API key"
                        labelPlacement="floating"
                        fill="outline"
                        placeholder="OpenAI API key"
                        onIonChange={e => setKey(e.detail.value!)} 
                        />
                      <div className='form-element'>
                        <IonButton onClick={handleAddKey}>
                          Update
                        </IonButton>
                      </div>
                    </>
                  )
                }

            </IonCardContent>
          </IonCard>
        )}

      {user && user.role === 'admin' && (
        <IonCard className='filled' style={{ padding: "10px" }}>
          <IonCardHeader>
            <IonCardTitle>Users</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
              {users && users.length > 0 ?              
                <ListView<User>
                  items={users}
                  columns={[
                    { attribute: 'name', label: 'Name', size: "small", primary: true },
                    { attribute: 'email', label: 'Email', size: "small" },
                    { attribute: 'role', label: 'Role', size: "small" },
                  ]}
                  enableReorder={false}
                  defaultLayout="table"
                  showActions={true}
                  onEdit={handleAccountEdit}
                  />
                :
                <IonProgressBar type="indeterminate" color="medium"></IonProgressBar>
              }

          </IonCardContent>
        </IonCard>)
      }

      <IonModal className='form-modal' isOpen={showAccountModal} onDidDismiss={() => setShowAccountModal(false)}>
        <div className='form'>
          <IonSelect className='form-element' 
              label="Role" 
              labelPlacement="floating" 
              interface="popover" 
              placeholder="Role"
              defaultValue={selectedAccount?.role}
              value={selectedAccount?.role}
              onIonChange={e => setSelectedAccount(prev => {
                if (prev && e.detail.value !== null && e.detail.value !== undefined) {
                    prev.role = e.detail.value;
                }
                return prev;
              })}>
              <IonSelectOption key="admin" value="admin">Admin</IonSelectOption>
              <IonSelectOption key="user" value="user">User</IonSelectOption>
          </IonSelect>
          <IonInput
            className='form-element'
            label="Name"
            labelPlacement="floating"
            fill="outline"
            value={selectedAccount?.name} 
            onIonChange={e => setSelectedAccount(prev => {
              if (prev && e.detail.value !== null && e.detail.value !== undefined) {
                  prev.name = e.detail.value;
              }
              return prev;
            })} 
            placeholder="Name"
          />          
          <IonInput
            className='form-element'
            label="Email"
            labelPlacement="floating"
            fill="outline"
            value={selectedAccount?.email} 
            onIonChange={e => setSelectedAccount(prev => {
              if (prev && e.detail.value !== null && e.detail.value !== undefined) {
                  prev.email = e.detail.value;
              }
              return prev;
            })} 
            placeholder="Email"
          />

          {!selectedAccount?.id ? <>
            <IonInput
              className='form-element'
              label="Password"
              labelPlacement="floating"
              fill="outline"
              value={accountPass} 
              onIonChange={e => setAccountPass(e.detail.value!)} 
              placeholder="Password"
            />
            <IonButton onClick={createAccount}>Create</IonButton>
          </> 
          :
            <IonButton onClick={updateAccount}>Save</IonButton>
          }

          <IonButton className='basic' onClick={() => { setShowAccountModal(false); }}>Cancel</IonButton>
          {selectedAccount?.email != user?.email && <IonButton color="danger" onClick={removeAccount}>Remove</IonButton>}
          </div>
        </IonModal> 
        
    </div>
  );
};

const Breadcrumb: React.FC = () => {
  return (
    <IonBreadcrumbs>
      <IonBreadcrumb routerLink="/account">Account</IonBreadcrumb>
    </IonBreadcrumbs>
  );
};

const MemoizedPage = React.memo(Page);
const MemoizedBreadcrumb = React.memo(Breadcrumb);

const Container: React.FC<{}> = () => { return <AppContainer ContentComponent={MemoizedPage} BreadcrumbComponent={MemoizedBreadcrumb} MenuComponent={Menu} />; };
export default Container;