import { IonBreadcrumbs, IonBreadcrumb, IonIcon } from '@ionic/react';
import AppContainer from '../../../AppContainer';
import { chatbubbleEllipses, diamond, folder } from 'ionicons/icons';
import Menu from '../../../components/Menu';
import { useContext, useEffect, useState } from 'react';
import GlobalContext from '../../../domain/GlobalContext';

interface Message {
  id: string;
  text: string;
  sender: string;
  timestamp: any;
}

const Page: React.FC = () => {

  const { initiative } = useContext(GlobalContext);  
  const [reportUrl, setReportUrl] = useState('');

  useEffect(() => {
    // Update reportUrl based on selectedDomain
    if (initiative?.name === 'webropol.com') {
      setReportUrl('https://docs.google.com/presentation/d/e/2PACX-1vSRXZ9YdwVe_WNgPgWgcr-S3F0bVWDDVvcfrvsJ8t4b-rjsp-nxw1QgwteAnKPQQx_T-79pNDVMaLQZ/embed?start=false&loop=false&delayms=3000');
    } else if (initiative?.name === 'www.staffpoint.fi') {
      setReportUrl('https://docs.google.com/presentation/d/e//2PACX-1vRg2GrNYNEb22YTFYSxtcbk6qtP-5JxFpPIhuiLZurshy7tqVdGcux4HL4GK5DdMiSwkR5SfoLwoliN/embed?start=false&loop=false&delayms=3000');
    } else {
      setReportUrl('/placeholder.html');
    }
}, [initiative]);

  return (
    <div>

      <iframe className="fullscreen" width="960" height="569" src={`${reportUrl}`}></iframe>

    </div>
  );
};

const Breadcrumb: React.FC = () => {
    return (
      <IonBreadcrumbs>
        <IonBreadcrumb routerLink="/position-analysis">
          <IonIcon slot="start" icon={folder}></IonIcon>
          Position analysis
        </IonBreadcrumb>
      </IonBreadcrumbs>
    );
  };
  
  const Container: React.FC<{}> = () => { return <AppContainer ContentComponent={Page} BreadcrumbComponent={Breadcrumb} MenuComponent={Menu} />; };
  export default Container;