
export const appConfig = {
    appName: 'MEOM AI',
    prismicRepository: 'meom-ai',
    initiatives: [ 'content' ],
    assistantId: "asst_xxxxxxxxxxxxxxxxxxxx",
    namespace: 'https://dashboard.meom.fi',
    auth0: true,
    auth0_domain: "dev-q3xhfi7wi1etigph.us.auth0.com",
    auth0_clientId: "E3ed5yOw3Od2oKktR7YmteuwPahZmbfu",
    auth0_scope: "read:current_user read:current_user_metadata",
    stripe_enabled: false,
    email_verification: false,
    signup_allowed: false,
    signin_google: false,
    display_mode: "dark",
    initiativeThread: false,
    initiativeThreadSummaries: false,
    initiativeTypes: [ "content" ],
    initiativeAssistants: {
        "content_disabled": "asst_O6lMSc5aOD4rOBqiasFkcovQ"
    }
};

export const firebaseConfig = {
    apiKey: "AIzaSyDCtnWgFNi-0IC1oU6VpAsk_E-o27_xhJY",
    authDomain: "ai-analytics-387514.firebaseapp.com",
    projectId: "ai-analytics-387514",
    storageBucket: "ai-analytics-387514.appspot.com",
    messagingSenderId: "350722286559",
    appId: "1:350722286559:web:a10ba8e73df77e00b75a6a"    
};