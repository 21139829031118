import AppContainer from '../AppContainer';
import { useState, useEffect, useContext } from 'react';
import {
  IonInput,
  IonTextarea,
  IonButton,
  IonProgressBar,
  IonBreadcrumb,
  IonBreadcrumbs,
  IonModal,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonIcon,
  ItemReorderEventDetail
} from '@ionic/react';
import GlobalContext from '../domain/GlobalContext';
import { api } from '../domain/ApiService';
import { Prompt } from '../domain/Types';
import { useLocation } from 'react-router-dom';
import { bookmark, bookmarkOutline, chatbubbleEllipses, desktop, folder, pin, pinOutline } from 'ionicons/icons';
import Menu from './Menu';
import ListView from './ListView';
import useAuth from '../domain/Auth';

type PromptsProps<T> = {
  showActions?: boolean | true;
  showHeader?: boolean | true;
  showSearch?: boolean | false;
  assistant_uid?: string | null;
  assistant_filter?: boolean | false;
  handleClick?: (item: Prompt) => void;
};

const Prompts = <T extends { [key: string]: any }>({
  showActions, showHeader, showSearch, assistant_uid, assistant_filter, handleClick
  }: PromptsProps<T>) => {


  const { user, initiative, prompts, setPrompts } = useContext(GlobalContext);

  const [ isFetching, setIsFetching ] = useState(false);
  const [ showModal, setShowModal ] = useState(false);

  const [ prompt, setPrompt ] = useState<Prompt | null>(null);

  const [filteredPrompts, setFilteredPrompts] = useState<Prompt[]>([]);

  useEffect(() => {
 
    fetchList();

  }, []);

  useEffect(() => {

    if (!showActions)
      showActions = true;

    if (!showHeader)
      showHeader = true;

  }, [showActions, showHeader]);  

  const fetchList = async () => {

    setIsFetching(true);

    try {
      
      if (assistant_uid != null) {

        let response: Prompt[] = await api('/prompt/list', { "assistant_uid": assistant_uid }, initiative);
        setPrompts(response);
        setFilteredPrompts(response);
      }
      else {

        let response: Prompt[] = await api('/prompt/list', {}, initiative);
        setPrompts(response);
        setFilteredPrompts(response);
      }
    } 
    catch (err) {
      console.error('Error fetching:', err);
    }

    setIsFetching(false);
};


const handleUpsert = async () => {

    console.log("handleUpsert: "+ prompt);
    if (!prompt) return;

    const endpoint = prompt.id ? '/prompt/update' : '/prompt/create';
    console.log(endpoint);
    try {

      setIsFetching(true);
      setShowModal(false);

      if (assistant_uid != null)
        prompt.assistant_uid = assistant_uid;

      console.log(endpoint +": "+ JSON.stringify(prompt, null, 2));

      await api(endpoint, prompt, initiative);
      await fetchList();
    } catch (err) {
      console.error('Error updating benefit:', err);
    }
  };

  const handleCancel = () => {

    setPrompt(null);
    setShowModal(false);
  };

  const handleRemove = async () => {
    try {
      if (!prompt || !prompt.id) return;
      setShowModal(false);
      await api('/prompt/remove', { "id": prompt.id }, initiative);
      await fetchList();
    } catch (err) {
      console.error('Error removing benefit:', err);
    }
  };

  const handleEdit = (item: Prompt | null) => {
    let editItem = item;
    if (editItem == null) {
      editItem = { "content": "" };
    }
    setPrompt(editItem);
    setShowModal(true);
  };

  function handleReorder(event: CustomEvent<ItemReorderEventDetail>) {
    console.log('Dragged from index', event.detail.from, 'to', event.detail.to);
    event.detail.complete();
  }

  const handleOnListClick = (item: Prompt | null) => {
    if (handleClick && item)
      handleClick(item);
  }

  const handlePin = async (item: Prompt | null) => {

    console.log("Pin: "+ assistant_uid);
    if (item && assistant_uid) {

      if (!item.pinnedAssistants)
        item.pinnedAssistants = [];

      const index = item.pinnedAssistants.indexOf(assistant_uid);

      if (index > -1) {
        item.pinnedAssistants.splice(index, 1);
        console.log("Assistant unpinned:", assistant_uid);
      } 
      else {

        item.pinnedAssistants.push(assistant_uid);
        console.log("Assistant pinned:", assistant_uid);
      }

      await api("/prompt/update", item, initiative);
      await fetchList();
    }
  }; 

  const handleSearchChange = (event: CustomEvent<{ value?: string | null }>) => {

    if (prompts == null) return;

    const searchText = event.detail.value?.toLowerCase() || '';

    let filteredPrompts = prompts.filter(prompt => {
        const promptLower = (prompt.content != null ? "" + prompt.content : "").toLowerCase();
        return promptLower.includes(searchText);
    });

    setFilteredPrompts(filteredPrompts);
  };  

  if (document || true) {

    return (
      <>
          <>
            {showSearch &&
            <IonInput 
                      className='form-element'
                      style={{width: "90%", marginLeft: "10px"}}
                      label="Search"
                      labelPlacement="floating"
                      fill="outline"
                      placeholder="Search"
                      onIonInput={handleSearchChange}
                  /> }

              {(assistant_uid) ? 
              <>
                  <ListView<Prompt>
                    items={filteredPrompts != null ? filteredPrompts : []}
                    columns={[
                      { attribute: 'content', label: 'Prompt', size: "large", primary: true },
                    ]}
                    enableReorder={false}
                    defaultLayout={'table'}
                    showActions={showActions}
                    showHeader={showHeader}
                    onEdit={handleEdit}                    
                    onReorder={handleReorder}
                    onClick={handleOnListClick}
                    />                    
              </>
              :
              <>
                    <ListView<Prompt>
                    items={filteredPrompts != null ? filteredPrompts : []}
                    columns={[
                      { attribute: 'content', label: 'Prompt', size: "small", primary: true }
                    ]}
                    enableReorder={false}
                    defaultLayout={'table'}
                    showActions={showActions}
                    showHeader={showHeader}
                    onEdit={handleEdit}                    
                    onReorder={handleReorder}
                    onClick={handleOnListClick}
                    />
                </>
              }
              <IonModal className='form-modal' isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
                <div className='form'>
                  <IonTextarea 
                    className='form-element'
                    label="Content"
                    labelPlacement="floating"
                    fill="outline"
                    rows={5}            
                    value={prompt?.content}            
                    onIonChange={e => setPrompt(prev => {
                      if (prev && e.detail.value !== null && e.detail.value !== undefined) {
                          prev.content = e.detail.value;
                      }
                      return prev;
                    })} 
                    placeholder="Content"
                  />
                  <IonButton onClick={handleUpsert}>Save</IonButton>
                  <IonButton className='basic' onClick={() => { setShowModal(false); }}>Cancel</IonButton>
                <IonButton color="danger" onClick={handleRemove}>Remove</IonButton>
                </div>
              </IonModal>
          </>
      </>      
    );
  }
  else {

    return (<></>);
  }  
}

export default Prompts;