import AppContainer from '../AppContainer';
import { useState, useEffect, useContext } from 'react';
import {
  IonInput,
  IonTextarea,
  IonButton,
  IonProgressBar,
  IonBreadcrumb,
  IonBreadcrumbs,
  IonModal,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonIcon,
  ItemReorderEventDetail,
  IonSelect,
  IonSelectOption
} from '@ionic/react';
import GlobalContext from '../domain/GlobalContext';
import { api } from '../domain/ApiService';
import { Assistant, Folder, Initiative, KnowledgeBaseItem } from '../domain/Types';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import { desktop, folder } from 'ionicons/icons';
import Menu from '../components/Menu';
import ListView from '../components/ListView';
import KnowledgeBase from '../components/KnowledgeBase';
import { v4 as uuidv4 } from 'uuid';
import useAuth from '../domain/Auth';

const Page: React.FC = () => {

  const { user, users, initiative, setInitiative, getInitiative, initiatives, setInitiatives } = useContext(GlobalContext);
  const [ isFetched, setIsFetched ] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const [templateName, setTemplateName] = useState<string>("");
  const [template, setTemplate] = useState<Initiative | undefined>(undefined);
  const [showFolderModal, setShowFolderModal] = useState(false);
  const [showAssistantModal, setShowAssistantModal] = useState(false);

  const [selectedOwners, setSelectedOwners] = useState<string[]>([ user?.auth_id ? user.auth_id : ""]);
  const [selectedCollaborators, setSelectedCollaborators] = useState<string[]>(['allUsers']);

  const [ folderConfig, setFolder ] = useState<Folder | null>(null);
  const [ assistant, setAssistant ] = useState<Assistant | null>(null);

  const history = useHistory();

  useEffect(() => {
    setIsFetched(true);

    if (initiative) {

      if (initiative.template_uid != null)  
        setTemplate(getInitiative(initiative.template_uid));

      setSelectedOwners(initiative?.owners ? initiative?.owners : [ user?.auth_id ? user.auth_id : ""])
      setSelectedCollaborators(initiative?.collaborators ?  initiative?.collaborators : ['allUsers']);
    }

  }, [initiative]); 

  const updateInitiative = async () => {
    if (!initiative) return;
    setIsFetched(false);
    try { 

      initiative.owners = selectedOwners;
      initiative.collaborators = selectedCollaborators;
      const response = await api("/initiative/update", initiative, initiative) as Initiative; 
      window.dispatchEvent(new Event('initiativeUpdated'));
    }
    catch (err) { console.error('Error:', err); }
    setShowModal(false);
    setIsFetched(true);
  };

  const syncInitiative = async () => {
    if (!initiative) return;
    setIsFetched(false);
    try { 

      initiative.owners = selectedOwners;
      initiative.collaborators = selectedCollaborators;
      const response = await api("/initiative/sync", initiative, initiative) as Initiative; 
      window.dispatchEvent(new Event('initiativeUpdated'));
    }
    catch (err) { console.error('Error:', err); }
    setShowModal(false);
    setIsFetched(true);
  };

  const removeInitiative = async () => {
    if (!initiative || !setInitiative) return;
    setIsFetched(false);
    setShowModal(false);
    try { 
      await api("/initiative/remove", { }, initiative);
      // setInitiative(null)
      window.dispatchEvent(new Event('initiativeRemoved'));
    }
    catch (err) { console.error('Error:', err); }
    setInitiative(undefined);
    history.push('/', {});
  };

  const updateFolder = async () => {
    setIsFetched(false);
    if (initiative != null) {

      if (initiative.folders == null)
        initiative.folders = [] 

      let folders = initiative.folders as Folder[];
      console.log("folders");
      if (!folders || folders == null)
        folders = []

      let currentFolder = folders.find(item => item === folderConfig);

      if (folderConfig != null && !currentFolder) {
          currentFolder = folderConfig;
          folders.push(currentFolder);
      }

      if (currentFolder && (!currentFolder.slug || currentFolder.slug === "")) {
        currentFolder.slug = currentFolder.name
            .toLowerCase()
            .replace(/\s+/g, '-')
            .replace(/[^\w-]/g, '')
            .replace(/^-+|-+$/g, '');
      }

      initiative.folders = folders;
    }
    await updateInitiative();
    setShowFolderModal(false);
    setFolder(null);
    setIsFetched(true);
  };

  const removeFolder = async () => {
    setIsFetched(false);  

    if (initiative != null) {

      let folders = initiative.folders as Folder[];
      initiative.folders = folders.filter(item => item !== folderConfig);
    }
    await updateInitiative();
    setShowFolderModal(false);
    setFolder(null);
    setIsFetched(true);
  };

  const handleFolderClick = (item: Folder) => {
    history.push('/'+ initiative?.name +'/'+ item.slug, {});
  };

  const handleFolderEdit = (item: Folder | null) => {
    let editItem = item;
    if (editItem == null) {    
      editItem = { "uid": uuidv4(), "name": "", "instructions": "", "slug": "", "knowledge_base": [], "assistants": [] };
    }
    setFolder(editItem);
    setShowFolderModal(true);
  };

  const handleReorder = (event: CustomEvent<ItemReorderEventDetail>) => {
    // Existing reorder logic
    console.log('Dragged from index', event.detail.from, 'to', event.detail.to);
    event.detail.complete();
  }; 

  const saveKnowledgeBase = (items: KnowledgeBaseItem[]) => {

    console.log("saveKnowledgeBase ...");
    if (initiative != null) {

      setIsFetched(false);
      initiative.knowledge_base = items;
      updateInitiative();
      setIsFetched(true);
    }
  }; 

  const updateAssistant = async () => {

    setIsFetched(false);
    if (!initiative) return;

    if (!initiative.assistants)
    initiative.assistants = [];

    if (assistant != null &&
        !initiative.assistants.find((item : Assistant ) => item === assistant)) {

          if (assistant && (!assistant.slug || assistant.slug == ""))
            assistant.slug = assistant.name.toLowerCase().replaceAll(" ", "-");

          initiative.assistants.push(assistant);
    }

    console.log(JSON.stringify(initiative))
    await updateInitiative();
    setShowAssistantModal(false);
    setAssistant(null);
    setIsFetched(true);
  };

  const removeAssistant = async () => {
    setIsFetched(false);  
    await updateInitiative();
    setShowAssistantModal(false);
    setAssistant(null);
    setIsFetched(true);
  };  

  const handleAssistantEdit = (item: Assistant | null) => {
    let editItem = item;
    if (editItem == null) {
      editItem = {  "uid": uuidv4(), "name": "", "instructions": "", "slug": "", "knowledge_base": [] };
    }
    setAssistant(editItem);
    setShowAssistantModal(true);
  };

  const handleAssistantClick = (item: Assistant) => {

    if (folderConfig?.slug)
      history.push('/'+ initiative?.name +'/'+ folderConfig?.slug +'/'+ item.slug, {});
    else
      history.push('/'+ initiative?.name +'/'+ item.slug, {});
  };

  const saveAsTemplate = async () => {

    if (!templateName) return;
    setIsFetched(false);
    try { 

      setInitiative(await api("/initiative/template", { "from_initiative": initiative?.id, "template_name": templateName }, initiative) as Initiative); 
      window.dispatchEvent(new Event('initiativeUpdated'));
    }
    catch (err) { console.error('Error:', err); }
    setShowModal(false);
    setShowTemplateModal(false);
    setIsFetched(true);
  };

  useEffect(() => {
    if (selectedCollaborators.length === 0) {
      setSelectedCollaborators(['allUsers']);
    }
  }, [selectedCollaborators]);  

  interface DataContainer {
    initiative: Initiative;
    template: Initiative;
  }
  
  function getNestedKnowledgeBaseItems(data: DataContainer, template: boolean = false): KnowledgeBaseItem[] {
    // Determine the base data source based on the 'template' flag
    const baseData = template ? data.template : data.initiative;
    if (!baseData) return [];
    
    // Collect knowledge bases from assistants directly under the base data
    const assistantKnowledgeBases: KnowledgeBaseItem[] = (baseData.assistants || []).flatMap((ass: Assistant) =>
      (ass.knowledge_base || []).map(kb => ({ ...kb, context_name: ass.name }))
    );
  
    // Collect knowledge bases from folders, including those from assistants within folders
    const folderKnowledgeBases: KnowledgeBaseItem[] = (baseData.folders || []).reduce((acc: KnowledgeBaseItem[], folder: Folder) => {
      // Add knowledge base from the folder itself if present
      if (folder.knowledge_base) {
        acc.push(...folder.knowledge_base.map(kb => ({ ...kb, context_name: folder.name })));
      }
      
      // Add knowledge bases from each assistant in the folder
      const assistantKbs = (folder.assistants || []).flatMap((ass: Assistant) =>
        (ass.knowledge_base || []).map(kb => ({ ...kb, context_name: `${folder.name} / ${ass.name}` }))
      );
      return acc.concat(assistantKbs);
    }, []);
  
    // Combine all knowledge bases
    return [...assistantKnowledgeBases, ...folderKnowledgeBases];
  }  

  if (document || (!(user?.email?.includes("laurea.fi")) && !(user?.email?.includes("oi.fi")) && !(user?.email?.includes("nbforum")) && !(user?.email?.includes("staffpoint")))) {

    const initiativeKnowledgeBaseItems = getNestedKnowledgeBaseItems({ initiative: initiative!, template: template! }, false);
    const templateKnowledgeBaseItems = getNestedKnowledgeBaseItems({ initiative: initiative!, template: template! }, true);  

    return (
      <>
      {(isFetched && initiative) ? (
          <>

            {(initiative.initiative_type == 'content') ? (
              <>
              </>
            ) : (
              <>
              <IonCard className='filled' style={{ padding: "10px" }}>
                <IonCardHeader>
                  <IonCardTitle>{initiative.name}</IonCardTitle>
                  <br />              
                  <big>{initiative.instructions}</big>
                </IonCardHeader>
                {user && user.role == 'admin' && (
                  <IonCardContent>
                    <IonButton className='basic' onClick={() => { setShowModal(true); }}>Modify</IonButton>
                    {!initiative.template ? 
                      <IonButton className='basic' onClick={() => { setShowTemplateModal(true); }}>Save as template</IonButton> 
                    : 
                      <IonButton className='basic' onClick={() => { setShowTemplateModal(true); }}>Duplicate template</IonButton>
                    }
                  </IonCardContent>
                )}
              </IonCard>

              <KnowledgeBase 
                items={initiative.knowledge_base} 
                templateItems={template?.knowledge_base} 
                onSave={saveKnowledgeBase} 
                label="Knowledge base" />

              <KnowledgeBase 
                items={initiativeKnowledgeBaseItems} 
                templateItems={templateKnowledgeBaseItems} 
                label="Knowledge bases in assistants"/>

              {(initiative.initiative_type == 'client') ? (
                <></>
              ) : (
                <IonCard className='filled' style={{ padding: "10px" }}>
                  <IonCardHeader>
                    <IonCardTitle>Assistants</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                      {initiative ? (
                        <>
                          <ListView<Assistant>
                            items={initiative.assistants}
                            columns={[
                              { attribute: 'name', label: 'Summary', size: "small", primary: true },
                              { attribute: 'instructions', label: 'Instructions', size: "large" }
                            ]}
                            showActions={user?.role == "admin"}
                            enableReorder={false}
                            defaultLayout="table"
                            onEdit={handleAssistantEdit}
                            onReorder={handleReorder}
                            onClick={handleAssistantClick}
                            />
                        </>
                      ) : (
                        <></>
                      )}
                  </IonCardContent>
                </IonCard>
              )}

              
              {initiative.initiative_type == "client" ? (
                <IonCard className='filled' style={{ padding: "10px" }}>
                  <IonCardHeader>
                    <IonCardTitle>Folders</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                      <ListView<Folder>
                        items={initiative.folders}
                        columns={[
                          { attribute: 'name', label: 'Summary', size: "small", primary: true },
                          { attribute: 'instructions', label: 'Instructions', size: "large" }
                        ]}
                        showActions={user?.role == "admin"}
                        enableReorder={false}
                        defaultLayout="table"
                        onEdit={handleFolderEdit}
                        onReorder={handleReorder}
                        onClick={handleFolderClick}
                        />
                  </IonCardContent>
                </IonCard>
              ) : (
                <></>
              )}

            </>
            )}

            <IonModal className='form-modal' isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
            <div className='form'>
              <IonInput
                className='form-element'
                label="Name"
                labelPlacement="floating"
                fill="outline"
                value={initiative.name} 
                onIonChange={e => setInitiative(prev => prev ? { ...prev, name: e.detail.value! } : prev)}
                placeholder="Initiative Name"
              />
            
              <IonTextarea 
                className='form-element'
                label="Instructions"
                labelPlacement="floating"
                fill="outline"
                rows={5}            
                value={initiative.instructions}
                onIonChange={e => setInitiative(prev => prev ? { ...prev, instructions: e.detail.value! } : prev)}
                placeholder="Instructions"
              />

             <IonSelect 
                    label="Owner access"
                    placeholder="Select users" 
                    multiple={true}
                    value={selectedOwners}
                    labelPlacement="floating" 
                    interface="popover"
                    onIonChange={(e) => {
                      const { value } = e.detail;
                      if (!value.length) {
                        setSelectedOwners([ user?.auth_id ? user.auth_id : ""]);
                      } else {
                        setSelectedOwners(value);
                      }
                    }}> 
                {users && users.map(instanceUser => (
                    <IonSelectOption key={instanceUser.auth_id} value={instanceUser.auth_id}>
                        {instanceUser.name}
                    </IonSelectOption>
                ))}
              </IonSelect> 

              <IonSelect
                label="Collaborator access"
                placeholder="Select users"
                multiple={true}
                labelPlacement="floating"
                value={selectedCollaborators}
                interface="popover"
                onIonChange={(e) => {
                  const { value } = e.detail;
                  if (value.includes('allUsers') && value.length > 1) {
                    setSelectedCollaborators(value.filter((v: string) => v !== 'allUsers'));
                  } else if (!value.length) {
                    setSelectedCollaborators(['allUsers']);
                  } else {
                    setSelectedCollaborators(value);
                  }
                }}
              >
                <IonSelectOption key="allUsers" value="allUsers" disabled={selectedCollaborators.length > 1}>
                  All workspace users 
                </IonSelectOption>
                {users && users.map(instanceUser => (
                  <IonSelectOption key={instanceUser.auth_id} value={instanceUser.auth_id}>
                    {instanceUser.name}
                  </IonSelectOption>
                ))} 
              </IonSelect>
              
              <div style={{marginTop: "10px"}}>
              <IonButton onClick={updateInitiative}>Save</IonButton>
              { initiative.template ? <IonButton className='basic' onClick={syncInitiative}>Save and sync</IonButton> : "" }
              <IonButton className='basic' onClick={() => { setShowModal(false); }}>Cancel</IonButton>
              <IonButton color="danger" onClick={removeInitiative}>Remove</IonButton>
              </div>
            </div>
            </IonModal>
          
            <IonModal className='form-modal' isOpen={showFolderModal} onDidDismiss={() => setShowFolderModal(false)}>
              <div className='form'>
                <IonInput
                  className='form-element'
                  label="Name"
                  labelPlacement="floating"
                  fill="outline"
                  value={folderConfig?.name} 
                  onIonChange={e => setFolder(prev => {
                    if (prev && e.detail.value !== null && e.detail.value !== undefined) {
                        prev.name = e.detail.value;
                    }
                    return prev;
                  })} 
                  placeholder="Name"
                />              
                <IonTextarea 
                  className='form-element'
                  label="Instruction"
                  labelPlacement="floating"
                  fill="outline"
                  rows={5}            
                  value={folderConfig?.instructions}
                  onIonChange={e => setFolder(prev => {
                    if (prev && e.detail.value !== null && e.detail.value !== undefined) {
                        prev.instructions = e.detail.value;
                    }
                    return prev;
                  })} 
                  placeholder="Instruction"
                />

                <IonButton onClick={updateFolder}>Save</IonButton>
                <IonButton className='basic' onClick={() => { setShowFolderModal(false); }}>Cancel</IonButton>
                <IonButton color="danger" onClick={removeFolder}>Remove</IonButton>

                </div>
              </IonModal>      

              <IonModal className='form-modal' isOpen={showAssistantModal} onDidDismiss={() => setShowAssistantModal(false)}>
                <div className='form'>
                  <IonInput
                    className='form-element'
                    label="Name"
                    labelPlacement="floating"
                    fill="outline"
                    value={assistant?.name} 
                    onIonChange={e => setAssistant(prev => {
                      if (prev && e.detail.value !== null && e.detail.value !== undefined) {
                          prev.name = e.detail.value;
                      }
                      return prev;
                    })} 
                    placeholder="Name"
                  />
                
                  <IonTextarea 
                    className='form-element'
                    label="Instruction"
                    labelPlacement="floating"
                    fill="outline"
                    rows={5}            
                    value={assistant?.instructions}
                    onIonChange={e => setAssistant(prev => {
                      if (prev && e.detail.value !== null && e.detail.value !== undefined) {
                          prev.instructions = e.detail.value;
                      }
                      return prev;
                    })} 
                    placeholder="Instruction"
                  />

                  <IonButton onClick={updateAssistant}>Save</IonButton>
                  <IonButton className='basic' onClick={() => { setShowAssistantModal(false); }}>Cancel</IonButton>
                  <IonButton color="danger" onClick={removeAssistant}>Remove</IonButton>

                </div>
              </IonModal>                  

              <IonModal className='form-modal' isOpen={showTemplateModal} onDidDismiss={() => setShowTemplateModal(false)}>
                <div className='form'>
                  <IonInput
                    className='form-element'
                    label="Name"
                    labelPlacement="floating"
                    fill="outline"
                    value={templateName} 
                    onIonChange={e => setTemplateName(e.detail.value!)}
                    placeholder="Name"
                  />
              
                  <IonButton onClick={saveAsTemplate}>Save</IonButton>
                  <IonButton className='basic' onClick={() => { setShowTemplateModal(false); }}>Cancel</IonButton>

                </div>
              </IonModal>                  
          </>
        ) : (

          <IonProgressBar type="indeterminate" color="medium"></IonProgressBar>

      )}
      </>      
    );
  }
  else {

    return (<></>);
  }  
}

const Breadcrumb: React.FC = () => {
  
  const { initiative } = useContext(GlobalContext);

  return (
    <IonBreadcrumbs>
      <IonBreadcrumb>
        <IonIcon slot="start" icon={desktop}></IonIcon>
        Overview
        </IonBreadcrumb>
    </IonBreadcrumbs>
  );
};

const Container: React.FC<{}> = () => { return <AppContainer ContentComponent={Page} BreadcrumbComponent={Breadcrumb} MenuComponent={Menu} />; };
export default Container;