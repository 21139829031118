import AppContainer from '../AppContainer';
import { useState, useEffect, useContext } from 'react';
import {
  IonInput,
  IonTextarea,
  IonButton,
  IonProgressBar,
  IonBreadcrumb,
  IonBreadcrumbs,
  IonModal,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonIcon,
  ItemReorderEventDetail,
  IonSpinner
} from '@ionic/react';
import GlobalContext from '../domain/GlobalContext';
import { api } from '../domain/ApiService';
import { Folder, Initiative, KnowledgeBaseItem } from '../domain/Types';
import { useLocation } from 'react-router-dom';
import { desktop, folder } from 'ionicons/icons';
import Menu from './Menu';
import ListView from './ListView';
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { v4 as uuidv4 } from 'uuid';
import useAuth from '../domain/Auth';
import { appConfig } from '../domain/Config';
import { on } from 'events';
import { useHistory } from 'react-router';

type KnowledgeBaseProps<T> = {
  items: KnowledgeBaseItem[] | null | undefined;
  templateItems?: KnowledgeBaseItem[] | null | undefined;
  folder_uid?: string | null;
  assistant_uid?: string | null;
  onSave?: (items: KnowledgeBaseItem[]) => void;
  label: string | null | undefined;
};

const KnowledgeBase = <T extends { [key: string]: any }>({
   items, templateItems, folder_uid, assistant_uid, onSave, label
  }: KnowledgeBaseProps<T>) => {

  if (!label)
    label = "Knowledge base"
  
  items?.forEach(item => {
    if (item.source_assistant_uid != null) {
      item.source_name = "From chat";
    }
  });

  const history = useHistory();
  const { user, initiative, setInitiative, getInitiative, initiatives, setInitiatives } = useContext(GlobalContext);
  const [ isFetched, setIsFetched ] = useState(false);
  const [ isFileLoaded, setIsFileLoaded ] = useState(true);
  const [showModal, setShowModal] = useState(false);
  
  const [ knowledgeBaseItem, setKnowledgeBaseItem ] = useState<KnowledgeBaseItem | null>(null);
  
  const uploadFile = async (file: File) => {
    try {

      if (!user) {
        console.error('Upload failed: No user.');
        return;
      }

      if (knowledgeBaseItem) {

        setIsFileLoaded(false);
        const storage = getStorage();
        const filePath = 'knowledge/'+ user.instance_id +'/' + knowledgeBaseItem.uid
        const storageRef = ref(storage, filePath); 
        const snapshot = await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(snapshot.ref);
        console.log('File uploaded: '+ downloadURL);
        const response: any = await api('/knowledge/summarize', { "url": downloadURL +"&name="+ file.name }, initiative);        
        console.log("Summary: "+ response.summary)
        setKnowledgeBaseItem(prev => {
          if (!prev) return null;        
          return {
            ...prev,
            content: response.summary,
            file_name: file.name
          };
        });
      }
      else {

        console.error('Knowledge base item missing');
      }

      setIsFileLoaded(true);

      // You can store this URL in Firestore or use it as needed
    } catch (error) {
      console.error('Upload failed', error);
    }
  };

  useEffect(() => {
    setIsFetched(true);
  }, [initiative]);

  const updateKnowledgeItem = async () => {

    setIsFetched(false);
    setShowModal(false);
    if (initiative != null) {
      
      let knowledge_base = items;

      if (knowledge_base == null)
        knowledge_base = [];

      let currentItem = knowledge_base.find(item => item === knowledgeBaseItem);

      if (knowledgeBaseItem != null && !currentItem) {
          currentItem = knowledgeBaseItem;
          knowledgeBaseItem.initiative_uid = initiative.uid;

          // if (initiative.template_uid) {
          // 
          //   let template = getInitiative(initiative.template_uid);
          //   knowledgeBaseItem.template_uid = initiative.template_uid;
          // }

          knowledge_base.push(currentItem);
          const response: any = await api('/knowledge/create', knowledgeBaseItem, initiative);        
          console.log(JSON.stringify(response))
      }
      else if (knowledgeBaseItem != null) {

          console.log("UPDATE: "+ JSON.stringify(knowledgeBaseItem))
          const response: any = await api('/knowledge/update', knowledgeBaseItem, initiative);        
          console.log(JSON.stringify(response))
      }

      items = knowledge_base;
    }

    if (items != null && onSave != undefined)
      onSave(items);

    setKnowledgeBaseItem(null);
    setIsFetched(true);
  };

  const removeKnowledgeItem = async () => {

    if (items == null) return;

    setIsFetched(false);
    setShowModal(false);
    if (initiative && knowledgeBaseItem) {

      items = items.filter(item => item !== knowledgeBaseItem);
      const response: any = await api('/knowledge/remove', { "uid": knowledgeBaseItem.uid }, initiative);        
      console.log(JSON.stringify(response))
    }

    if (onSave != undefined)
      onSave(items);

    setKnowledgeBaseItem(null);
    setIsFetched(true);
  };  

  const handleItemEdit = (item: KnowledgeBaseItem | null) => {
    if (!initiative) return;
  
    let editItem = item;
    if (editItem == null) {
      editItem = { uid: uuidv4(), content: "", initiative_id: initiative.id };
  
      if (folder_uid) {
        editItem.folder_uid = folder_uid;
  
        // const matchingFolder = initiative.folders?.find(folder => folder.uid === folder_uid);
  
        // if (matchingFolder) {
        //   editItem.template_folder_uid = matchingFolder.template_uid;
        // }
      }
  
      if (assistant_uid) {
        editItem.assistant_uid = assistant_uid;
      }
    }
  
    setKnowledgeBaseItem(editItem);
    setShowModal(true);
  };

  const handleReorder = (event: CustomEvent<ItemReorderEventDetail>) => {
    // Existing reorder logic
    console.log('Dragged from index', event.detail.from, 'to', event.detail.to);
    event.detail.complete();
  }; 

  const FileUploadComponent = () => {
    const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        uploadFile(file);
      }
    };
  
    return (
      <input type="file" onChange={onFileChange} />
    );
  };

  const [urls, setUrls] = useState<{ [key: string]: string | null }>({});

  const fetchSignedUrl = async (fileId : string) => {

    if (!fileId) return null;

    try {

      const storage = getStorage();
      const filePath = 'knowledge/'+ user?.instance_id +'/' + fileId
      const storageRef = ref(storage, filePath);
      return await getDownloadURL(storageRef);
    } 
    catch (error) {
      console.error('Error fetching signed URL:', error);
    }

    return null;
  };

  const openFile: (item: KnowledgeBaseItem | null | undefined) => void = (item) => {
    if (item && item.uid) {
        fetchSignedUrl(item.uid).then(url => {
            if (url)
              window.open(url, '_blank');
            else
              console.error("url was null");
        }).catch(error => {
            console.error('Error fetching signed URL:', error);
        });
    } else {
        console.log('No file attribute provided.');
    }
  };

  const openContext: (item: KnowledgeBaseItem | null | undefined) => void = (item) => {
    if (!initiative || !initiative.folders) {

      console.log('No initiative provided.');
      return;
    }
    
    if (item && item.uid) {
      
      const folder = initiative.folders.find(f => f.uid === item.folder_uid);

      if (folder) {
          const folderSlug = folder.slug;
          let path = `${initiative.name}/${folderSlug}`;

          if (folder.assistants && item.assistant_uid) {
              const assistant = folder.assistants.find(a => a.uid === item.assistant_uid);
              if (assistant) {
                  path += `/${assistant.slug}`;
              }
          }
          else {  
            console.log('No assistant attribute provided.');
          }

          history.push(path);
      }
      else {
        console.log('No folder attribute provided.');
      }

    } else {
        console.log('No context attribute provided: '+ JSON.stringify(item));
    }
  };

  if (document || true) {

    return (
      <>
      {(initiative) ? (

          <>

            <IonCard className='filled' style={{ padding: "10px" }}>
              <IonCardHeader>
                <IonCardTitle>{label}</IonCardTitle>
              </IonCardHeader>
              <IonCardContent>
                  <ListView<KnowledgeBaseItem>
                    items={items != null ? items : []}
                    columns={[
                      { attribute: 'content', label: 'Content', size: "small", primary: true },
                      { attribute: 'file_name', onClick: openFile, label: 'File', size: "small", primary: false },
                      { attribute: 'source_name', label: ' ', size: "small", primary: false },
                      { attribute: 'context_name', label: ' ', size: "small", primary: false }
                    ]}
                    enableReorder={false}
                    showActions={user?.role == "admin" && onSave != undefined}
                    defaultLayout="table"
                    onEdit={onSave != undefined ? handleItemEdit : undefined}
                    onReorder={handleReorder}
                    />

                  { templateItems != null && templateItems.length > 0 ? 
                  <>
                  <div className='mid-header'>From template</div>
                  <ListView<KnowledgeBaseItem>
                    items={templateItems != null ? templateItems : []}
                    columns={[
                      { attribute: 'content', label: 'Content', size: "small", primary: true },
                      { attribute: 'file_name', onClick: openFile, label: 'File', size: "small", primary: false },
                      { attribute: 'context_name', label: ' ', size: "small", primary: false }
                    ]}
                    enableReorder={false}
                    showActions={false}
                    defaultLayout="table"
                    onEdit={undefined}
                    onReorder={undefined}
                    />  
                    </>
                    : <></>
                    }            
              </IonCardContent>
            </IonCard>

              <IonModal className='form-modal' isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
                <div className='form'>

                <IonTextarea 
                  className='form-element'
                  label="Content"
                  labelPlacement="floating"
                  fill="outline"
                  rows={5}            
                  value={knowledgeBaseItem?.content}            
                  onIonChange={e => setKnowledgeBaseItem(prev => {
                    if (prev && e.detail.value !== null && e.detail.value !== undefined) {
                        prev.content = e.detail.value;
                    }
                    return prev;
                  })} 
                  placeholder="Content"
                />

                {/*
                <div style={{padding: "10px"}}><input type="file" name="File" id="fileToUpload" /></div>
                */}
                <div className='form-file-upload'> 

                {
                  !isFileLoaded ?
                    <IonSpinner name="dots" color="dark"></IonSpinner>
                  :
                  (knowledgeBaseItem?.file_name ?                 
                    <>{knowledgeBaseItem?.file_name}</> 
                  : 
                    <FileUploadComponent /> 
                  )
                }
                  
                </div>
                <IonButton disabled={!isFileLoaded} onClick={updateKnowledgeItem}>Save</IonButton>
                <IonButton className='basic' onClick={() => { setShowModal(false); }}>Cancel</IonButton>
                <IonButton disabled={!isFileLoaded} color="danger" onClick={removeKnowledgeItem}>Remove</IonButton>

                </div>
              </IonModal>
          
          </>
        ) : (

          <IonProgressBar type="indeterminate" color="medium"></IonProgressBar>

      )}
      </>      
    );
  }
  else {

    return (<></>);
  }  
}

export default KnowledgeBase;