import Account from '../pages/Account';
import Prompts from '../pages/Prompts';
import { NestedRoute, Initiative } from './Types';

import contentRoutes from '../modules/content/Routes'

const coreRoutes: NestedRoute[] = [
  { path: '/account', component: Account, exact: true, label: "account" },
  { path: '/prompts', component: Prompts, exact: true, label: "prompts" }
];

const generateRoutes = (initiative : Initiative): NestedRoute[] => {

  if (!initiative || initiative == null)
    return [];

  if (initiative.initiative_type === "content") {
    
    return [...coreRoutes, ...contentRoutes];
  } 
  else {
    
    return [...coreRoutes];
  }  
}

export default generateRoutes;