import AppContainer from '../AppContainer';
import { useState, useEffect, useContext } from 'react';
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonIcon,
  IonLabel,
  IonRow,
  IonProgressBar,
  IonGrid,
  IonItem,
  IonList,
  IonBreadcrumbs,
  IonBreadcrumb
} from '@ionic/react';
import { useLocation } from 'react-router-dom';
import { api } from '../domain/ApiService';
import { card, checkmark } from 'ionicons/icons';
import BillingButton from '../components/BillingButton';
import useAuth from '../domain/Auth';
import React from 'react';
import Menu from '../components/Menu';
import GlobalContext from '../domain/GlobalContext';

const Page: React.FC = () => {

  console.log('RENDER: Plan.tsx');
  const { user } = useContext(GlobalContext);
  const [usage, setUsage] = useState(0);
  const [cost, setCost] = useState(0.0);
  const location = useLocation();  
 
  return (

    <div>
    </div>
  );
};

const Breadcrumb: React.FC = () => {
  return (
    <IonBreadcrumbs>
      <IonBreadcrumb>
        <IonIcon slot="start" icon={card}></IonIcon>
        Usage & billing
      </IonBreadcrumb>
    </IonBreadcrumbs>
  );
};

const MemoizedPage = React.memo(Page);
const MemoizedBreadcrumb = React.memo(Breadcrumb);

const Container: React.FC<{}> = () => { return <AppContainer ContentComponent={MemoizedPage} BreadcrumbComponent={MemoizedBreadcrumb} MenuComponent={Menu} />; };
export default Container;