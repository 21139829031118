import { Redirect, Route } from 'react-router-dom';
import { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { IonApp, IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonItem, IonList, IonPage, IonRouterOutlet, IonSpinner, IonToast, setupIonicReact, useIonToast } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { appConfig } from './domain/Config';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

import { PrismicProvider } from '@prismicio/react'
import { prismicClient } from './domain/Prismic'

import { initializeApp } from 'firebase/app';

import useAuth from './domain/Auth';

import { IonIcon } from '@ionic/react';
import { mailOutline } from 'ionicons/icons';
import GlobalContext from "./domain/GlobalContext";
import { api } from "./domain/ApiService";
import { PrismicContentType, Initiative, NestedRoute, Prompt, User } from "./domain/Types";
import { auth } from "./domain/Firebase";
import generateRoutes from './domain/Routes';
import Login from "./Login";
import React from 'react';
import InitiativePage from './pages/Initiative';
import Account from './pages/Account';
import Prompts from './pages/Prompts';
import Templates from './pages/Templates';
import Plan from './pages/Plan';
import { firebaseConfig } from './domain/Config';
import Verification from './Verification';
import { getAuth, applyActionCode, onAuthStateChanged } from 'firebase/auth';
import { config } from 'process';

initializeApp(firebaseConfig);

setupIonicReact({
  mode: 'md'
});

const App: React.FC = () => {
 
  const [present] = useIonToast();

  const { logout, isAuthenticated, isLoading, loginWithRedirect } = useAuth();
  const [token, setToken] = useState<string | null>(null); 
  const [user, setUser] = useState<User | null>(null);
  const [users, setUsers] = useState<User[] | null>(null);
  const [initiatives, setInitiatives] = useState<Initiative[] | null>(null);
  const getInitiative = (uid: string) => initiatives?.find(initiative => initiative.uid === uid);
  const [initiative, setInitiative] = useState<Initiative>();
  const [prompts, setPrompts] = useState<Prompt[]>([]);
  const [nestedRoutes, setNestedRoutes] = useState<NestedRoute[]>([]);
  const [isDarkMode, setIsDarkMode] = useState<boolean>(true);
  const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false);
  const [smallScreen, setSmallScreen] = useState<boolean>(false);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [prismicContent, setPrismicContent] = useState<PrismicContentType>({ navigation: {} });
  const [eventTrigger, setEventTrigger] = useState(0);

  let isLoadingUserinfo = false;
  let knownRoutes = ["/", "/plan", "/account", "/prompts", "/templates"];

  useEffect(() => {
    
    console.log("Checked: "+ (window as any).location.pathname);
    if (initiative == null && !knownRoutes.includes((window as any).location.pathname)) {

      // Replace this by loading the given initiative
      (window as any).location = "/";
    }

  }, [initiative]);

  useEffect(() => {

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const actionCode = urlParams.get('oobCode');
    const actionMode = urlParams.get('mode');

    if (actionCode && actionMode === 'verifyEmail') {

      const auth = getAuth();

      const presentToast = (message: string) => {
        present({
          message: message,
          duration: 5000,
          position: "top",
        });
      };

      applyActionCode(auth, actionCode)
        .then(() => {
          onAuthStateChanged(auth, async (user) => {
            if (user) {
              (window as any).accessToken = await user.getIdToken(true);
              setToken((window as any).accessToken);
              console.log("Token renewed")
              const updatedUserInfo = await api("/userinfo", { "reset": true }, undefined);
              console.log("User info updated - email_verified: ", updatedUserInfo.email_verified);
              setUser(updatedUserInfo);
              presentToast("Email verified");
            }
          });
        })
        .catch((error) => {

          console.error(error);
          presentToast("Verification failed: "+ error);
        });
    }
  }, [present]);

  const reloadUser = async () => {
        
    if (!isLoadingUserinfo) {

      isLoadingUserinfo = true;
      console.log("Load user info");
      const userinfo = await api("/userinfo", {}, undefined);
      userinfo.type = "Firebase";

      // if (userinfo.instance_id != "*") {

        console.log(JSON.stringify(userinfo));
        setUser(userinfo);
      // }
    }

    isLoadingUserinfo = false;
  };

  useEffect(() => {
  
    window.addEventListener('uerAuhenticated', reloadUser);
  
    return () => {
      window.removeEventListener('uerAuhenticated', reloadUser);
    };
  }, []);

  useEffect(() => {

    const fetchUsers = async () => {
      const response = await api("/user/list", {}, initiative);
      setUsers(response);
    };

    if (user)
      fetchUsers();

  }, [user]);

  useEffect(() => {
    const handleInitiativeUpdate = () => {
      setEventTrigger(prev => prev + 1);
    }; 

    window.addEventListener('initiativeUpdated', handleInitiativeUpdate);

    return () => {
      window.removeEventListener('initiativeUpdated', handleInitiativeUpdate);
    };
  }, []);

  useEffect(() => {

    if (initiative) {

      setNestedRoutes(generateRoutes(initiative));
      document.title = `${appConfig.appName} - ${initiative.name}`;
    }
  }, [initiative, initiative?.assistants, initiative?.folders, eventTrigger]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      // Authentication state change logic
    });

    return unsubscribe; // Cleanup subscription on unmount
  }, [isAuthenticated]);

  const updateBodyClass = useCallback((event: MediaQueryListEvent) => {

    if (appConfig.display_mode && appConfig.display_mode == "dark") {

      document.body.classList.toggle('dark', true);
      setIsDarkMode(true);
    }
    else {

      document.body.classList.toggle('dark', event.matches);
      setIsDarkMode(event.matches);
    }    
}, []);
 
  useEffect(() => {

    if (token)
      (window as any).accessToken = token;

    if (appConfig.display_mode && appConfig.display_mode == "dark") {

      document.body.classList.toggle('dark', true);
      setIsDarkMode(true);
      return () => {};
    }
    else {

      const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
      prefersDark.addEventListener('change', updateBodyClass);
      document.body.classList.toggle('dark', prefersDark.matches);
      return () => prefersDark.removeEventListener('change', updateBodyClass);
    }
  }, [token, updateBodyClass]);

  const listInitiatives = useCallback(async () => {
    try {

      if (user != null) {

        console.log("APP /initiative/list")
        const response = await api("/initiative/list", {}, initiative);
        setInitiatives(response);
        window.dispatchEvent(new Event('initiativesLoaded'));
      }
  } catch (err) {
      console.error('Failed to fetch initiatives:', (err as Error).message);
    }
  }, [user]);

  useEffect(() => {
    if (token) {
      window.addEventListener('initiativeCreated', listInitiatives);
      window.addEventListener('initiativeUpdated', listInitiatives);
      window.addEventListener('initiativeRemoved', listInitiatives);
      listInitiatives();
      return () => {
        window.removeEventListener('initiativeCreated', listInitiatives);
        window.removeEventListener('initiativeUpdated', listInitiatives);
        window.removeEventListener('initiativeRemoved', listInitiatives);
      };
    }
  }, [user, listInitiatives]);

  const renderRoutes = (nestedRoutes: NestedRoute[], basePath: string = ''): JSX.Element[] => {
    return nestedRoutes.flatMap((route, index): JSX.Element[] => {

      const routeElement: JSX.Element = (
        <Route
          key={`${basePath}${route.path}`}
          path={`${basePath}${route.path}`}
          component={route.component}
          exact={route.exact}
        />
      );
  
      // Conditionally render child routes
      const childRoutes: JSX.Element[] = route.children 
        ? renderRoutes(route.children, `${basePath}${route.path}`)
        : [];
  
      return [routeElement, ...childRoutes];
    });
  };
  
  useEffect(() => {
    console.log("User state changed in App.tsx:", user);
  }, [user]);

  return (
    <IonReactRouter>
    <PrismicProvider client={prismicClient}>
      <GlobalContext.Provider value={{ 
        user, setUser, 
        token, setToken,
        users, setUsers, 
        initiatives, setInitiatives, 
        initiative, setInitiative, 
        getInitiative,
        prompts, setPrompts, 
        isDarkMode, setIsDarkMode, 
        isMenuVisible, setIsMenuVisible, 
        smallScreen, setSmallScreen, 
        showMenu, setShowMenu, 
        prismicContent, setPrismicContent 
      }}>
        <IonApp>
          {isLoading ? (
            <IonPage id="wrapper-page">
              <IonContent fullscreen>
                <div className="centered-content content-container">
                  <IonSpinner name="dots" color="dark" class="large-spinner"></IonSpinner>
                </div>
              </IonContent>
            </IonPage>
          ) : isAuthenticated ? (
            user == null ? (
              <IonPage id="wrapper-page">
                <IonContent fullscreen>                  
                  <div className="centered-content content-container">
                    <IonSpinner name="dots" color="dark" class="large-spinner"></IonSpinner>
                  </div>
                </IonContent>
              </IonPage>
            ) : !user.email_verified && appConfig.email_verification ? (
              <IonPage id="wrapper-page">
                <IonContent fullscreen>
                  <div className="centered-content content-container">
                    <Verification />
                  </div>
                </IonContent> 
              </IonPage>
            ) : (
                <IonRouterOutlet>
                  <Route exact path="/" component={InitiativePage} />
                  <Route exact path="/plan" component={Plan} />
                  <Route exact path="/account" component={Account} />
                  <Route exact path="/prompts" component={Prompts} />
                  <Route exact path="/templates" component={Templates} />
                  {renderRoutes(nestedRoutes, "/" + initiative?.name)}
                </IonRouterOutlet>
            )
          ) : appConfig.auth0 ? (
            // If appConfig.auth0 is true, trigger login with redirect
            // Note: This block will redirect and not render any components
            (() => { loginWithRedirect({ screen_hint: window.location.pathname.includes("signup") ? 'signup' : undefined }); return null; })()
          ) : (
            <IonPage id="wrapper-page">
              <IonContent fullscreen>
                <div className="centered-content content-container">
                  <Login />
                </div>
              </IonContent>
            </IonPage>
          )}
        </IonApp>
      </GlobalContext.Provider>
    </PrismicProvider>
    </IonReactRouter>
  );  
}
export default App;
